import './style.css'
import { motion, useAnimation, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import checkFileType from '../../../utils/CheckFileType';
import { People } from 'react-bootstrap-icons';
import Popup from '../../popupanimaitons';
const ThreePictureDescriptionSection = ({ resource1, resource2, resource3 ,desc ,isLast}) => {
    const controls = useAnimation();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"],
    });
    useTransform(scrollYProgress, (pos) => {
        console.log(pos, "task")


        if (pos < 0.1 && pos > 0) {
            controls.start("initial");
        }
        else if (pos < 0.9 && pos > 0.6) {
            controls.start("start");
        }
    });

    const Varients = {
        initial: {
            opacity: 0,
            y: -500,
            x: -500
        },
        start: {
            opacity: 1,
            y: 0,
            x: 0,

        },

    }

    return (
        <motion.div ref={targetRef} className="container-fluid bg-lightgreen custom-padding section-padding min-vh-100 ">
            <div className="row mt-md-5 mt-1 d-lg-flex  d-none">
                <div className="col-lg-11 col-md-11 col-sm-12 col-12 ps-5">
                    <Popup>
                        
                    <div className="three-picture-section-layout-2-wrapper1 mx-auto d-flex">
                        <div className="item1  mx-auto  my-auto  ratio ratio-21x9    rounded">


                            <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                        </div>
                        <div className="three-picture-section-layout-2-wrapper3  ms-auto  align-self-end  mt-5">

                            <div className='item1  ratio ratio-16x9    rounded' >


                                <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                        
                        </Popup>

                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div className="three-picture-section-layout-2-wrapper2 d-flex align-items-center  ">
                        <Popup>

                        <div className='item1  ratio ratio-21x9   rounded overflow-hidden' >


                            <img className='  ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                        </div>
                        </Popup>
                    </div>
                </div>
                <div className="col-1"></div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-12  d-flex algin-items-end ">
                    <motion.p
                        animate={controls}
                        variants={Varients}
                        className='three-picture-section-layout-2-text align-self-center d-none d-md-block '>{desc}
                        </motion.p>
                    <p

                        className='three-picture-section-layout-2-text align-self-center d-block d-md-none'>{desc}
                        </p>

                </div>
            </div>
            <div className="row mt-5  d-lg-none  d-flex">
                <div className="col-md-12 mb-5">
                    <Popup>

                    <div className="style-info-section-wrapper1">
                         <div className="item1  ratio ratio-1x1    rounded">
                            {
                                checkFileType(resource3) === 'Video' ? <video className=' my-auto mx-auto ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>

                    </div>
                    </Popup>

                </div>
                <div className="col-md-12 mt-5">
                    <Popup>

                    <div className="section2 item2  ratio ratio-1x1    rounded">
                            {
                                checkFileType(resource2) === 'Video' ? <video className=' my-auto mx-auto ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                    </div>
                    </Popup>
                </div>
            </div>
        </motion.div>
    )
}

export default ThreePictureDescriptionSection
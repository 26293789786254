import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import ResumeSingleVideoSection from '../components/pagesComponents/ResumeSingleVideoSection'
import FourPictureSection from '../components/pagesComponents/FourPictureSection'
import video1 from '../assets/video/gaellefashionweek/1.MP4'

import video4 from '../assets/video/gaellefashionweek/4.MP4'

import video10 from '../assets/video/gaellefashionweek/video2.mov'
import video11 from '../assets/video/gaellefashionweek/video3.mov'
import video12 from '../assets/video/gaellefashionweek/video4.mp4'
import video5 from '../assets/video/gaellefashionweek/video5.mp4'



import image1 from '../assets/video/gaellefashionweek/1.JPG'
import image2 from '../assets/video/gaellefashionweek/2.JPG'
import image3 from '../assets/video/gaellefashionweek/3.JPG'
import image4 from '../assets/video/gaellefashionweek/4.JPG'
import image5 from '../assets/video/gaellefashionweek/5.JPG'
import image6 from '../assets/video/gaellefashionweek/6.JPG'
import image7 from '../assets/video/gaellefashionweek/7.JPG'
import image8 from '../assets/video/gaellefashionweek/8.JPG'
import image10 from '../assets/video/gaellefashionweek/13.JPG'
import image11 from '../assets/video/gaellefashionweek/18.png'


import image9 from '../assets/video/gaellefashionweek/17.png'
import image12 from '../assets/video/gaellefashionweek/19.png'
import image13 from '../assets/video/gaellefashionweek/20.png'
import Contact from '../components/Contact'





const GaelleFashionweek = () => {
const taskdesc=  " We collaborated with Gaelle Paris creating an innovative format specifically designed for the Milan Fashion Week period. The main goal was to enhance the brand by leveraging the participation of our talents in the fashion week events."

const task = ["CONTENT CREATION" , "PRODUCTION" , "INFLUENCER MARKETING" ," BRAND EXPERIENCE"]


const resumeDesc ="The project involved the participation of 8 talents. Throughout the day, the creators produced content to show the events and activities they took part in, wearing a total outfit from Gaelle Paris. The day concluded with a social dinner among the talents involved in the project. We managed and coordinated the entire experience producing a total of 8 posts, 11 reels, and 40 instagram stories."


const styledesc= "The content has been shared on the brand’s channels as well as on the creator's profiles."

  return (
    <>
    <TwoVideoBanner resource1={video1} resource2={image9} title={"Gaelle Fashion week"}  />
    <OurTask task={task} desc={taskdesc} heading={"The Projects"} />
    <ResumeSingleVideoSection desc={resumeDesc} resource1={video10} secondVideo={true}  resource2={image1} righttext={"THE DINNER"} isLast={false}/>
    <StyleInfoSection desc={styledesc} resource1={image4} resource2={image10} resource3={image11} resource4={video11} isLast={false} />
    <FourPictureSection resource1={image12} resource2={image13} resource3={video12} resource4={video5} />
    <Contact />


    </>
  )
}

export default GaelleFashionweek
import React from "react";
import { motion,  } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import Guess from "../assets/img/project/Guess.png";
import Gaelle from "../assets/img/project/Image Gaelle fashion week.png";

import lancome from '../assets/img/project/lancome.png';
import EsteLauder from '../assets/img/project/EsteeLauder.png';
import puma from '../assets/img/project/puma.png';
import Charelotte from '../assets/img/project/image Charlotte Tilbury.png';
const AllProjects = () => {
const navigate = useNavigate()
  return (
    <div
      className="bg-dark container-fluid  custom-padding d-lg-flex d-none "
      style={{ width: "100vw" }}
    >
      <div className="row" style={{ marginTop: "10%" }}>

        <div className="col-lg-3">

          <div
            style={{
              position: "sticky",
              top: "15%",
              bottom: "0%",
              left: "500px",
              right: "500px ",
            }}

          >
            <h1 className="fw-bold fs-3 text-white">Filter</h1>
            <Link className=" text-white mt-5 p text-decoration-none d-block ">All</Link>
            <Link to='/project/influencer' className="p text-white text-decoration-none d-block "> Influencer experience</Link>
            <Link to='/project/content' className="p text-white text-decoration-none d-block">Content creation</Link>
            <Link  to='/project/production' className="p text-white text-decoration-none d-block">Production</Link>
          </div>
        </div>
        <motion.div
          className="col-lg-9"

        >
          <ProjectScroll>
            <div className="allProject-img1"
            >
              <div className="row  ">
                <div className="col-md-6 col-lg-6       me-3">
                  <div className="row  bg-white rounded-5 hover-image">
                    <div className="py-3">

                      <h1 className="project-heading text-center">Guess</h1>
                      <p className="project-subheading text-center text-center">Content creation</p>
                    </div>
                    <div className="col-md-10  g-0  ">
                      <motion.img
                        initial={{ opacity: 1 }}

                        src={Guess}
                        className="img-fluid w-100 "
                        alt=""
                      />
                    </div>
                    <div className="overlay-chnage">
                      <button  onClick={()=>navigate('/guess')} className="text-change px-3 py-2 px-md-5 py-md-3">
                        See more
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" col-md-5 col-lg-5  allProject-img2  align-self-end ">
                  <div className="row rounded-5  bg-white  hover-image">
                    <div className=" col-md-12   py-3 ">
                      <h1 className="project-heading text-center">Lancome</h1>
                      <p className="project-subheading text-center">Content Creation</p>
                    </div>
                    <div className="col-md-12 g-0">
                      <motion.img

                        src={lancome}
                        className="img-fluid w-100"
                        alt=""
                      />
                    </div>
                    <div className="overlay-chnage">
                      <button  onClick={()=>navigate('/furla')} className="text-change px-3 py-2 px-md-5 py-md-3">
                        See more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ProjectScroll>
          <ProjectScroll

          >
            <div className="row">
              <div className="col-md-8 col-lg-8   allProject-img3  offset-md-4   ">
                <div className="row  bg-white rounded-5 hover-image">
                  <div className="col-md-8 g-0  ">
                    <motion.img
                      initial={{ x: 250, y: -300, opacity: 0 }}
                      whileInView={{ x: 0, y: 0, opacity: 1 }}
                      transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
                      src={EsteLauder}
                      className="img-fluid w-100"
                      alt=""
                    />
                  </div>
                  <div className="overlay-chnage">
                    <button  onClick={()=>navigate('/estee')} className="text-change px-3 py-2 px-md-5 py-md-3">
                      See more
                    </button>
                  </div>
                  <div className="col-md-4 align-self-center">
                    <h1 className="project-heading text-center">Estee Lauder</h1>
                    <p className="project-subheading text-center">Content creation</p>
                  </div>
                </div>
              </div>
              <div className=" col-md-6 col-lg-6  allProject-img4 mt-4 ">
                <div className="row rounded-5  bg-white hover-image">
                  <div className=" col-md-4   align-self-center ">
                    <h1 className="project-heading text-center">Gaelle Paris</h1>
                    <p className="project-subheading text-center">Brand experience</p>
                  </div>
                  <div className="col-md-7 offset-md-1 g-0">
                    <motion.img
                      initial={{ x: 0, y: -500, opacity: 0 }}
                      whileInView={{ x: 0, y: 0, opacity: 1 }}
                      transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
                      src={Gaelle}
                      className="img-fluid w-100 w-100"
                      alt=""
                    />
                  </div>
                  <div className="overlay-chnage">
                    <button  onClick={()=>navigate('/gaellefashionweek')} className="text-change px-3 py-2 px-md-5 py-md-3">
                      See more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ProjectScroll>
          <ProjectScroll


          >
            <div className="row  ">
              <div className="col-md-5 col-lg-5 align-self-end   allProject-img5 g-0    me-3">
                <div className="row g-0 bg-white rounded-5 hover-image">
                  <div className="col-md-12 g-0  ">
                    <motion.img
                      initial={{ x: 250, y: -500, opacity: 0 }}
                      whileInView={{ x: 0, y: 0, opacity: 1 }}
                      transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
                      src={puma}
                      className="img-fluid w-100 w-100"
                      alt=""
                    />
                  </div>
                  <div className="overlay-chnage">
                    <button  onClick={()=>navigate('/puma')} className="text-change px-3 py-2 px-md-5 py-md-3">
                      See more
                    </button>
                  </div>
                  <div className="col-md-12 text-center py-3">
                    <h1 className="project-heading text-center text-center">Puma</h1>
                    <p className="project-subheading text-center  text-center">
                      Content creation
                    </p>
                  </div>

                </div>
              </div>
              <div className=" col-md-6 col-lg-6   allProject-img6 ">
                <div className="row rounded-5  bg-white hover-image">
                  <div className=" col-md-12   text-center py-3   ">
                    <h1 className="project-heading text-center  text-center">Charlotte Tilbury</h1>
                    <p className="project-subheading text-center  text-center">
                      Brand Experience
                    </p>
                  </div>
                  <div className="overlay-chnage">
                    <button  onClick={()=>navigate('/charlottetilbury')} className="text-change px-3 py-2 px-md-5 py-md-3">
                      See more
                    </button>
                  </div>
                  <div className="col-md-12 g-0">
                    <motion.img
                      initial={{ x: -250, y: -500, opacity: 0 }}
                      whileInView={{ x: 0, y: 0, opacity: 1 }}
                      transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
                      src={Charelotte}
                      className="img-fluid w-100 w-100"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </ProjectScroll>
        </motion.div>
      </div>
    </div>
  );
};

export default AllProjects;




const ProjectScroll = ({ children }) => {




  return (
    <>
      <motion.div
       
        className="  bg-dark vh-100  "
        initial={{ opacity: 0, scale: 0 }}
        style={{
          transformOrigin: 'top bottom', marginBottom: "10%",
          padding: "31px 68px 0px 77px"
        }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 250,
          damping: 70,
        }}
      >
        {children}
      </motion.div>
    </>
  )

}


import React from "react";
import WhatWeDoBanner from "../components/WhatWeDoBanner";
import WhatWeDoInfo from "../components/WhatWeDoInfo";
import WhatWeDoAccordin from "../components/WhatWeDoAccordin";
import Contact from "../components/Contact";
import WhatWeDOPicture from "../components/WhatWeDOPicture";

const WhatWeDo = () => {
  return (
    <>
      <WhatWeDoBanner />
      <WhatWeDoInfo />
      <WhatWeDoAccordin />
      <WhatWeDOPicture />
      <Contact />
    </>
  );
};

export default WhatWeDo;

import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'



const Mevlani = () => {
  return (
    <>
    <TwoVideoBanner title={"Mevlani"}  />
    <OurTask />
    <StyleInfoSection />


    </>
  )
}

export default Mevlani
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
// import React, {  } from "react";
// import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo/Logo.png";
import LogoLight from "../assets/img/logo/Logo Premium ID.png";
import LogoBlack from "../assets/img/logo/LogoLight.png"
import { useTheme } from "../Context/useTheme";
import MenuIconOpen from "../assets/svg/menu-icon.svg";
import MenuIconClose from "../assets/svg/menu-close.svg";
import { useMediaQuery } from "../hooks/useMediaQuery";
import {useNavigate} from 'react-router-dom';
import { motion, useAnimation } from "framer-motion";

const Header = () => {
const navigate = useNavigate();

  const [navColor, setNavColor] = useState(false)
  const mobileView = useMediaQuery('(max-width: 992px)');
  const [showNav, setShowNav] = useState(mobileView ? false : true)
  console.log(showNav, "showNav")
  console.log(mobileView, "mobileView")
  const { theme, isHome } = useTheme();

  console.log(theme, isHome, "header")
  const location = useLocation()
  const pathname = location.pathname
  const controls = useAnimation();
  // change background color fucntion for navbar start
  const changeBackground = () => {
    if (!mobileView) {
      if (window.scrollY >= 66) {
        setNavColor(true)
      } else {
        setNavColor(false)
      }
    }
  }
  // change background color fucntion for navbar end
  useEffect(() => {

    if (mobileView) {
      setNavColor(true)
    } else {
      changeBackground()
      window.addEventListener("scroll", changeBackground)
    }
    // adding the event when scroll change background

    setShowNav(false)
    window.history.scrollRestoration = "manual"
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, mobileView])

  useEffect(() => {

    window.history.scrollRestoration = "manual"
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  
  const navVariants = {
    open: { y: 0, opacity: 1, transition: { type: 'tween', ease: 'easeInOut', duration: 0.8 } },
    closed: { y: -1000, opacity: 0, transition: { type: 'tween', ease: 'easeInOut', duration: 0.8 } }
  };
  useEffect(() => {
    if (mobileView) {
      if (showNav) {
        controls.start("open");
      } else {
        controls.start("closed");
      }
    } else {
      controls.start("open");
    }
  }, [showNav, controls, mobileView]);
  return (
    <>
      {
        mobileView ?

          <nav className={`navbar navbar-expand-lg navbar-light bg-navbar  fixed-top  `}>
            <div className="container-fluid custom-padding  ">
              <Link className="navbar-brand " to="/">
                <img
                  src={LogoLight}
                  alt="Logo"
                  className="img-fluid"
                  width={"237"}
                  height={"49"}
                />
              </Link>
              <button
                className={`navbar-toggler text-white `}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="true"
                aria-label="Toggle navigation"
                onClick={() => setShowNav(!showNav)}
              >
                {!showNav ?
                  <img src={MenuIconOpen} alt="" />
                  :
                  <img src={MenuIconClose} alt="" />
                }
              </button>
              <motion.div
                className={`${mobileView ? "custom-collapse" : "collapse"}  navbar-collapse me-auto ${showNav ? "show mobile-nav  min-vh-100" : ""}`}
                variants={navVariants} // Apply the variants
                initial={`${mobileView ? "closed" : "open"}`} // Start in the 'closed' state
                animate={controls}
              >
                <ul className="navbar-nav  ms-auto">
                  <motion.li className="nav-item"
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0, transition: { duration: 0.5, delay: 0.1 } }}
                  >
                    <Link
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'}`}
                      style={{
                        borderBottom: pathname.includes('talent') ? '1px solid white' : undefined,
                      }}
                      aria-current="page"
                      to="/talent"
                    >
                      Talent
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">01</span>
                  </motion.li>
                  <motion.li className="nav-item"
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0, transition: { duration: 0.5, delay: 0.3 } }}
                  >
                    <Link
                      style={{
                        borderBottom: pathname.includes('whatwedo') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/whatwedo">
                      What we do
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">02</span>
                  </motion.li>
                  <motion.li className="nav-item"
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0, transition: { duration: 0.5, delay: 0.5 } }}
                  >
                    <Link
                      style={{
                        borderBottom: pathname.includes('project') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link  text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/project">
                      Projects
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">03</span>
                  </motion.li>
                  <motion.li className="nav-item"
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0, transition: { duration: 0.5, delay: 0.7 } }}
                  >
                    <Link
                      style={{
                        borderBottom: pathname.includes('contact') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/contact">
                      Contacts
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">04</span>
                  </motion.li>
                </ul>
                <div className="d-lg-none  d-md-block text-center menu-member-content">
                  <motion.div
                    initial={{ height: 0 }}
                    whileInView={{ height: "100px", transition: { duration: 0.5, delay: 0.9 } }}
                    className="v-line-member m-auto"></motion.div>
                  <div className="pt-3 pb-3"></div>
                  <motion.button
                  onClick={()=>navigate("/contact")}
                    initial={{ x: "-100%" ,opacity:0}}
                    whileInView={{ x: 0, opacity:1,transition: { duration: 0.5, delay: 1.2 } }}
                    className="btn btn-outline-light rounded-pill btn-lg inline-twelve nav-member-btn">
                    Go Premium
                    {/* <ArrowRight className="ms-1" size={24} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                        stroke="#F1F2F2"
                        stroke-width="2"
                      />
                    </svg>
                  </motion.button>

                </div>
              </motion.div>

            </div>
          </nav> :
          <nav className={`navbar navbar-expand-lg navbar-light ${navColor ? "bg-navbar" : "bg-transparent"} fixed-top  `}>
            <div className="container-fluid custom-padding  ">
              <Link className="navbar-brand " to="/">
                <img
                  src={isHome ? Logo : !isHome ? theme === "light" ? LogoLight : LogoBlack : Logo}
                  alt="Logo"
                  className="img-fluid"
                  width={"237"}
                  height={"49"}
                />
              </Link>
              <button
                className={`navbar-toggler text-white `}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarText"
                aria-controls="navbarText"
                aria-expanded="true"
                aria-label="Toggle navigation"
                onClick={() => setShowNav(!showNav)}
              >
                {!showNav ?
                  <img src={MenuIconOpen} alt="" />
                  :
                  <img src={MenuIconClose} alt="" />
                }
              </button>
              <div
                className={`"collapse navbar-collapse me-auto`}
                variants={navVariants} // Apply the variants
                initial={`${mobileView ? "closed" : "open"}`} // Start in the 'closed' state
                animate={controls}
              >
                <ul className="navbar-nav  ms-auto">
                  <li className="nav-item">
                    <Link
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'}`}
                      style={{
                        borderBottom: pathname.includes('talent') ? '1px solid white' : undefined,
                      }}
                      aria-current="page"
                      to="/talent"
                    >
                      Talent
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">01</span>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottom: pathname.includes('whatwedo') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/whatwedo">
                      What we do
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">02</span>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottom: pathname.includes('project') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link  text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/project">
                      Projects
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">03</span>
                  </li>
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottom: pathname.includes('contact') ? '1px solid white' : undefined,
                      }}
                      className={`nav-link text-${theme} ${theme === 'light' ? 'customhoverjunaidtwo' : 'customhoverjunaid'} `} to="/contact">
                      Contacts
                    </Link>
                    <span className="nav-mob-number d-lg-none d-inline ">04</span>
                  </li>
                </ul>
                <div className="d-md-none  d-sm-block text-center menu-member-content">
                  <div className="v-line-member m-auto"></div>
                  <div className="pt-3 pb-3"></div>
                  <Button variant="outline-light" className="rounded-pill btn-lg inline-twelve nav-member-btn" onClick={() => navigate('/project')}>
                    Go Premium
                    {/* <ArrowRight className="ms-1" size={24} /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                        stroke="#F1F2F2"
                        stroke-width="2"
                      />
                    </svg>
                  </Button>
                </div>
              </div>

            </div>
          </nav>
      }
    </>
  );
};

export default Header;

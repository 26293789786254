import React, { useEffect, useRef } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Project1 from "../assets/img/projects/1.png";
import Project2 from "../assets/img/projects/2.png";
import Project3 from "../assets/img/projects/3.png";
import { useTheme } from "../Context/useTheme";
import { useNavigate } from "react-router-dom";
import HeadingAnimation from "./HeadingAnimation";

const ProjectDesktopHome = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });
  const sectionViewCheck = useInView(targetRef);
  const position = useTransform(scrollYProgress, (pos) => {
    return pos > 0.2 ? "sticky" : "static";
  });
  const positionimg1 = useTransform(scrollYProgress, (pos) => {
    return pos > 0.36 ? "sticky" : "static";
  });
  const opacityimage1 = useTransform(scrollYProgress, [0.47, 0.49], [1, 0]);
  const opacityimage2 = useTransform(scrollYProgress, [0.68, 0.7], [1, 0]);

  const positionimg2 = useTransform(scrollYProgress, (pos) => {
    return pos > 0.6 ? "sticky" : "static";
  });
  const positionimg3 = useTransform(scrollYProgress, (pos) => {
    return pos > 0.85 ? "sticky" : "static";
  });
  const hrOpacity = useTransform(scrollYProgress, [0, 0.4], [0, 1]);

  const { toggleTheme, toggleisHome } = useTheme();

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const navigate = useNavigate();
  return (
    <>
      <motion.div
        ref={targetRef}
        className="container-fluid  project  bg-dark section-padding  position-relative"
      >
        <motion.div
          style={{
            position,
            top: "10%",
            bottom: "0%",
            left: "0%",
            right: "0% ",
            overflow: "hidden",
          }}
        >
          <div className="d-flex align-items-center  justify-content-center">
            <HeadingAnimation
              elementType={"h1"}
              classes="big-heading font-antonio text-light me-2"
            >
              LAST PROJECTS
            </HeadingAnimation>
            <p className="align-self-end  number-text inline-nineteen">03</p>
          </div>
          <div className="row image-row">
            <div className="col-md-12 col-lg-2 col-12 d-flex flex-column  justify-content-between  align-items-center">
              <p
                transition={{
                  type: "spring",
                  stiffness: 70,
                  damping: 40,
                  delay: 0.3,
                  duration: 1,
                }}
                className="text-white text-center   mx-auto inline-twenty"
              >
                Premium <br />
                purpose
                <motion.hr
                  style={{
                    opacity: hrOpacity,
                    position: "relative",
                    top: "-3vw",
                    left: "8vw",
                  }}
                />
              </p>
              <p
                onClick={() => navigate("/project")}
                className="text-white inline-twenty customhoverjunaidtwo"
              >
                View all
              </p>
            </div>
            <div className="col-md-12    col-lg-7 col-12 g-0 "></div>

            <div className="col-md-12 col-lg-3 col-12    pe-3  d-flex flex-column  justify-content-between  align-items-center">
              <p className="text-white col-8  text-justify inline-twenty">
                Each collab is a synergy between our talents and brands,
                crafting authentic and{" "}
                <span className="text-primary ">remarkable campaigns.</span>
              </p>
              <button
                className="btn btn-outline-light rounded-pill btn-lg become-permium  "
                onClick={() => navigate("/contact")}
              >
                <span>Go premium </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                  style={{ marginTop: "1%" }}
                >
                  <path
                    d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                    stroke="#F1F2F2"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ marginBottom: "30vw" }}
          style={{
            position: positionimg1,
            top: "35vh",
            bottom: "0%",
            left: "19%",
            right: "19% ",
            opacity: opacityimage1,
          }}
          transition={{
            type: "spring",
            stiffness: 70,
            damping: 40,
            delay: 0.3,
            duration: 1,
          }}
          className="project-scroll-img w-50 overflow-hidden scrolly-img  bg-light rounded-5 mx-auto d-flex align-items-center justify-content-center align-self-center"
        >
          <div className="overlay">
            <button
              className="btn px-5 py-3  btn-outline-dark btn-lg fs-2"
              onClick={() => navigate("/gaellefashionweek")}
            >
              See More
            </button>
          </div>
          <img className="img-fluid  w-100 " src={Project2} alt="" />
        </motion.div>
        <motion.div
          initial={{ marginBottom: "30vw" }}
          style={{
            position: positionimg2,
            opacity: opacityimage2,
            top: "33vh",
            bottom: "0%",
            left: "19%",
            right: "19% ",
          }}
          className="project-scroll-img w-50 overflow-hidden scrolly-img bg-light rounded-5  mx-auto d-flex align-items-center justify-content-center align-self-center"
        >
          <div className="overlay">
            <button
              className="btn px-5 py-3  btn-outline-dark btn-lg fs-2"
              onClick={() => navigate("/charlottetilbury")}
            >
              See More
            </button>
          </div>
          <img className="img-fluid  w-100 " src={Project1} alt="" />
        </motion.div>
        <motion.div
          style={{
            position: positionimg3,
            top: "30vh",
            bottom: "0%",
            left: "19%",
            right: "19% ",
          }}
          className=" project-scroll-img w-50 overflow-hidden scrolly-img bg-light rounded-5    mx-auto d-flex align-items-center justify-content-center align-self-center"
        >
          <div className="overlay">
            <button
              className="btn px-5 py-3 btn-outline-dark btn-lg fs-2"
              onClick={() => navigate("/guess")}
            >
              See More
            </button>
          </div>
          <img className="img-fluid  w-100 " src={Project3} alt="" />
        </motion.div>
      </motion.div>
    </>
  );
};

export default ProjectDesktopHome;

import { useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import i1 from "../assets/img/whatwedo/1.JPG";
import i11 from "../assets/img/whatwedo/11.jpg";
import i12 from "../assets/img/whatwedo/12.JPG";
import i13 from "../assets/img/whatwedo/13.JPG";
import i2 from "../assets/img/whatwedo/2.JPG";
import i3 from "../assets/img/whatwedo/3.jpg";
import i4 from "../assets/img/whatwedo/4.JPG";
import i5 from "../assets/img/whatwedo/5.jpg";
import i6 from "../assets/img/whatwedo/6.jpg";
import i7 from "../assets/img/whatwedo/7.jpg";
import i8 from "../assets/img/whatwedo/8.JPG";
import i9 from "../assets/img/whatwedo/9.jpg";
import video from "../assets/img/whatwedo/video.mp4";
import video1 from "../assets/img/whatwedo/video2.mp4";

const WhatWeDOPicture = () => {
  const targetRef = useRef(null);
  const sectionViewCheck = useInView(targetRef);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  useEffect(() => {
    if (sectionViewCheck) {
      videoRef1.current.play();
      videoRef2.current.play();
    } else {
      videoRef1.current.pause();
      videoRef2.current.pause();
    }
  }, [sectionViewCheck]);
  const A = [i1, i2, i3, i4, i5, i6];
  const C = [i7, i8, i9, i11, i12, i13];

  console.log(sectionViewCheck, "sectionViewCheck");
  return (
    <div className="h-100" ref={targetRef}>
      <div className="container-fluid bg-dark what-we-section   my-sm-0 my-auto min-vh-100  ">
        <div className="inner-items what-we-section-padding">
          <div className="row ">
            <div className="col-lg-6  d-flex  align-items-start justify-content-center d-none  d-md-block ">
              <video
                className="w-100 wahtwedovideo "
                src={video}
                ref={videoRef1}
                loop
                playsInline
                muted
              ></video>
            </div>
            <div className="col-lg-6  mt-5 mt-md-0 d-flex  align-items-center align-items-md-start justify-content-center ">
              <video
                className="w-100 wahtwedovideo "
                src={video1}
                ref={videoRef2}
                loop
                playsInline
                muted
              ></video>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark what-we-section what-we-section-padding  my-sm-0 my-auto min-vh-100  ">
        <div className=" what-slideshow mb-5 mt-5">
          <div className="row">
            <div className="col-lg-6 g-0">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                effect="fade"
                speed={2000}
                modules={[EffectFade, Autoplay]}
              >
                {A.map((e, i) => (
                  <SwiperSlide className="w-100 h-100" key={i}>
                    <div class="swiper-content" sy>
                      <img src={e} className="w-100 h-100 img-fluid" alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-lg-6 g-0 d-md-block d-none ">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
                effect="fade"
                speed={2000}
                modules={[EffectFade, Autoplay]}
              >
                {C.map((e, i) => (
                  <SwiperSlide className="w-100 h-100" key={i}>
                    <div class="swiper-content">
                      <img src={e} className="w-100 h-100 img-fluid" alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDOPicture;

import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import OurTask from '../components/pagesComponents/OurTask'
import TwoVideoSection from '../components/pagesComponents/TwoVideoSection'
import video1 from '../assets/video/lancome/1.MP4'
import video2 from '../assets/video/lancome/2.MP4'
import video3 from '../assets/video/lancome/3.MP4'
import Contact from '../components/Contact'


const Furla = () => {
  const desc ="She created a total of 7 content, reels and tik toks, showing Lancome’s latest products. The content has been shared on the brand’s channels being promoted through Martina’s profile through an advertising campaign."
  const task =["ADVERTISING" , "CONTENT CREATION" ,"INFLUENCER MARKETING"  ,"IGC CREATION"
]
  return (
    <>
    <DescriptionBanner video={video1}  title={"Lancome"} desc={desc}  />
    <OurTask heading={"IN DETAILS"} desc={desc}  task={task}/>
    <TwoVideoSection  image1={video2} image2={video3} isLeft={true} section2paddingTop={false}/>
    <Contact />


    </>
  )
}

export default Furla
import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import pullandbearvideo from '../assets/video/pullandbearstore/1.MP4'
import Contact from '../components/Contact'

const Pullbear = () => {
const desc = "We have partnered with Pull & Bear for a content creation campaign, celebrating the opening of their new store in Arese, Milan. Our talent Greta Filippeschi attended a shopping experience documented by our production team"
  return (
    <>
    <DescriptionBanner video={pullandbearvideo}  title={"Pull and Bear store tour"}  desc={desc}/>
    {/* <VideoSection /> */}
    <Contact />
    </>
  )
}

export default Pullbear
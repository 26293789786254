import checkFileType from '../../../utils/CheckFileType'
import Popup from '../../popupanimaitons'
import './style.css'
const FourPictureSection = ({resource1,resource2,resource3,resource4}) => {
    return (
        <div className="container-fluid bg-lightgreen custom-padding section-padding min-vh-100 ">
            <div className="row mt-md-5 mt-1">
            
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <Popup>


                    <div className="Four-picture-section-wrapper1 mx-auto d-flex  flex-md-row flex-row-reverse align-items-center  justify-content-center position-relative">
                <div className="item1  ratio ratio-1x1    rounded overflow-hidden">
                            {
                                checkFileType(resource3) === 'Video' ? <video className=' my-auto mx-auto ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                            </div>
                    <div className="item2 ratio ratio-21x9    rounded overflow-hidden">
                            {
                                checkFileType(resource1) === 'Video' ? <video className=' my-auto mx-auto ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                            </div>
                    </div>
                    </Popup>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                    <Popup>


                    <div className="Four-picture-section-wrapper2 ">
                    <div className="item1  ratio ratio-16x9    rounded overflow-hidden">
                            {
                                checkFileType(resource2) === 'Video' ? <video className=' my-auto mx-auto ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                            </div>
                    </div>
                    </Popup>
                </div>
                <div className="col-lg-4  col-md-4 col-sm-12  col-12 ">
                    <Popup>

                <div className="Four-picture-section-wrapper3  ">
                <div className="item1  ratio ratio-1x1  mx-auto   rounded overflow-hidden">
                            {
                                checkFileType(resource4) === 'Video' ? <video className=' my-auto mx-auto ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                            </div>
                    </div>
                    </Popup>
                </div>
            </div>

        </div>
    )
}

export default FourPictureSection
import { Pagination, A11y, EffectCreative, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import adidas from "../assets/img/brandslider/ADIDAS.png";
import aboutyou from "../assets/img/brandslider/ABOUTYOU.png";

import Brosway from "../assets/img/brandslider/Brosway.png";
import Bershka from "../assets/img/brandslider/Bershka.png";

import crocs from "../assets/img/brandslider/CROCS.png";

import collister from "../assets/img/brandslider/collistar.png";
import clinique from "../assets/img/brandslider/clinique.png";
import guess from "../assets/img/brandslider/guess.png";
import estee_lauder from "../assets/img/brandslider/estee_lauder.png";

import daniel from "../assets/img/brandslider/DANIELWELLINGTON.png";
import farfetch from "../assets/img/brandslider/FARFETCH.png";
import filorga from "../assets/img/brandslider/filorga.png";
import furla from "../assets/img/brandslider/Furla.png";
import gaelle from "../assets/img/brandslider/Gaelle_Paris.png";
import garnier from "../assets/img/brandslider/Logo_Garnier.png";
import nike from "../assets/img/brandslider/NIKE.png";
import pull_bear from "../assets/img/brandslider/PULL_BEAR.png";
import puma from "../assets/img/brandslider/PUMA.png";
import sephora from "../assets/img/brandslider/sephora.png";
import superga from "../assets/img/brandslider/superga.png";

import snipes from "../assets/img/brandslider/SNIPES.png";
import Charlotte from "../assets/img/brandslider/charlotte_tilbury.png";
import loreal from "../assets/img/brandslider/loreal.png";
import lancome from "../assets/img/brandslider/lancome.png";



import React, { useEffect, useRef } from "react";
import { useInView } from "framer-motion";
import { useTheme } from "../Context/useTheme";

const BrandSlider = ({ setisLight }) => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <>
      <div
        ref={targetRef}
        className="container-fluid  brand-slider bg-dark section-padding custom-padding "
      >
        <Swiper
          grabCursor={true}
          // effect={"creative"}
          autoplay={{
            delay: 3600,
          }}
          speed={1000}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-130%", 0, 0],
            },
            next: {
              translate: ["100%", "-100%", "-100%"],
              opacity: 0,
            },
          }}
          modules={[Pagination, A11y, EffectCreative, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className="pb-5"
         
        >
          <SwiperSlide>
            <div className="brand-grids ">
              <div className="slider-image">
                <img src={farfetch} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={pull_bear} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={nike} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={garnier} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={lancome} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={puma} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={aboutyou} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={sephora} alt="" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="brand-grids">
              <div className="slider-image">
                <img src={snipes} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={superga} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={Bershka} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={adidas} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={crocs} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={collister} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={clinique} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={Brosway} alt="" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="brand-grids">
              <div className="slider-image">
                <img src={furla} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={guess} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={gaelle} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={estee_lauder} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={filorga} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={daniel} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={Charlotte} alt="" className="img-fluid" />
              </div>
              <div className="slider-image">
                <img src={loreal} alt="" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default BrandSlider;

import React, { useEffect, useRef } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { useTheme } from "../Context/useTheme";
import { useLocation, useNavigate } from "react-router-dom";
import HeadingAnimation from "./HeadingAnimation";

const Contact = () => {
  const targetRef = useRef(null);
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const { pathname } = useLocation();
  const { toggleTheme, toggleisHome } = useTheme();

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });
  console.log(pathname);

  const sectionViewCheck = useInView(targetRef);

  useTransform(scrollYProgress, (pos) => {
    console.log(pos, "contect checker");

    if (sectionViewCheck) {
      if (pathname !== "/project") {
        if (pos > 0.99) return toggleTheme("light");
      } else {
        if (sectionViewCheck) {
          if (pos > 0.9) {
            toggleTheme("light");
          } else {
            toggleTheme("dark");
          }
          if (pos <= 0) {
            return toggleTheme("light");
          }
        }
      }
    }
  });

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const navigate = useNavigate();
  return (
    <>
      <div
        ref={targetRef}
        className="container-fluid  bg-dark contact  section-padding custom-padding position-relative h-auto"
      >
        <motion.div
          initial={{ height: "0%" }}
          whileInView={{ height: "100%" }}
          transition={{
            type: "spring",
            stiffness: 70,
            damping: 40,
            delay: 0.4,
            duration: 3,
          }}
          className="line"
        ></motion.div>
        <div className="row inline-six" ref={elementRef}>
          <div className="col-12 col-md-12 col-sm-12 col-lg-7">
            <div className="row mb-4">
              <div className="col-md-12 col-12 col-lg-10">
                <HeadingAnimation
                  elementType={"h1"}
                  classes="text-antonio text-white font-telegraf fw-bolder mt-5"
                >
                  Let's get in touch
                </HeadingAnimation>
                <HeadingAnimation
                  elementType="h2"
                  classes={`text-primary font-telegraf  ${
                    isInView ? "heading-animation" : ""
                  }`}
                >
                  TALK TO US
                </HeadingAnimation>
              </div>
            </div>
          </div>
          <div className="row d-block  d-lg-none">
            <div className="col-md-12 text-center  ">
              <div className="stroked-white-wrapper bg-dark position-relative bolding-class ">
                <div
                  onClick={() => navigate("/contact")}
                  className="stroked-white "
                  data-content="CONTACT"
                >
                  CONTACT
                </div>
              </div>
              {/* <h1 className=" display-2"></h1> */}
            </div>
          </div>
          <div className="col-6 col-md-6 col-sm-6 col-lg-2 position-relative">
            <motion.div
              initial={{ height: "0%" }}
              whileInView={{ height: "100%" }}
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 40,
                delay: 0.4,
                duration: 3,
              }}
              className="mobile-view-contact-line"
            ></motion.div>
            <div className="d-flex flex-column align-items-start justify-content-start  h-100 contant-btn">
              <button
                className="btn btn-outline-light rounded-pill   btn-lg  border border-1 border-light  text-light"
                onClick={() => navigate("/talent")}
              >
                Talent
              </button>
              <button
                className="btn btn-outline-light rounded-pill mt-4 btn-lg  border border-1 border-light  text-light"
                onClick={() => navigate("/whatwedo")}
              >
                What we do
              </button>
              <button
                className="btn btn-outline-light rounded-pill mt-4 btn-lg  border border-1 border-light  text-light"
                onClick={() => navigate("/project")}
              >
                Projects
              </button>
              <button
                className="btn btn-outline-light rounded-pill mt-4 btn-lg  border border-1 border-light  text-light"
                onClick={() => navigate("/contact")}
              >
                Contact
              </button>
            </div>
          </div>
          <div className="col-6 col-md-6 col-sm-6 col-lg-3">
            <div className="d-flex flex-column align-items-end justify-content-between h-100 contant-btn">
              <a
                href="https://www.instagram.com/premiumidagency?igsh=YXZweDM2NWc4enh6 "
                target="blank"
                className="btn btn-outline-light rounded-pill btn-lg  border border-1 border-light  text-light"
              >
                Instagram
              </a>
              <a
                href="https://www.linkedin.com/company/premiumidagency/ "
                target="_blank"
                className="mt-4 btn btn-outline-light rounded-pill btn-lg  border border-1 border-light  text-light"
                rel="noreferrer"
              >
                Linkedin
              </a>

              <p className="mt-5 fs-6">Privacy</p>
              <p className="fs-6 text-end">Terms and conditions</p>
            </div>
          </div>
        </div>

        <div className="row d-none  d-lg-block">
          <div className="col-md-12  ">
            <div className="stroked-white-wrapper bg-dark position-relative bolding-class text-center  ">
              <div
                onClick={() => navigate("/contact")}
                className="stroked-white text-center  "
                data-content="CONTACT"
              >
                CONTACT
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 d-lg-flex d-md-block  d-block justify-content-between align-items-center mt-5 lower-footer">
          <p className="col-8 col-lg-12 text-start contact-end-text inline-eleven footer-cotact-mobile">
            Premium International Development Srl 12303800960 | Via Lambertenghi
            7, 20159 Milano
          </p>
          <p className="contact-end-text">PremiumID 2024</p>
        </div>
      </div>
    </>
  );
};

export default Contact;

import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import video1 from '../assets/video/guess/1.webm'
import image1 from '../assets/video/guess/1.JPG'
import image2 from '../assets/video/guess/2.JPG'
import image3 from '../assets/video/guess/3.JPG'
import image4 from '../assets/video/guess/4.JPG'
import image5 from '../assets/video/guess/5.JPG'
import Contact from '../components/Contact'



const Guess = () => {
  const desc = 'We partnered with Guess in a content creation project for promoting their new denim bags. Our talents Alessandra Belda and Benedetta Bottin created special content to be shared on their channels.'
  return (
    <>
    <TwoVideoBanner  title={"Guess"}  resource1={video1} resource2={image5} />
    <StyleInfoSection desc={desc} resource1={image3} resource2={image4} resource3={image3} resource4={image5} isLast={true}/>
    <Contact />


    </>
  )
}

export default Guess
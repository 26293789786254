import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useInView, useAnimation, useTransform, useScroll } from "framer-motion";
import { Tab, Nav, Col } from "react-bootstrap";
import { useTheme } from "../Context/useTheme";
import aboutyou from "../assets/img/otherworkproject/aboutyou.png";
import calliope from "../assets/img/otherworkproject/calliope.png";
import durax from "../assets/img/otherworkproject/durax.png";
import gaelleexp from "../assets/img/otherworkproject/gaelleexp.png";
import ImagePullandBearexperience from "../assets/img/otherworkproject/Image Pull and Bear experience.png";
import pullandbearpreview from "../assets/img/otherworkproject/pullandbearpreview.png";
import riverisland from "../assets/img/otherworkproject/riverisland.png";
import superga from "../assets/img/otherworkproject/superga.png";


const ProjectOtherWork = () => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const [activeKey, setActiveKey] = useState(null);
  const navigate = useNavigate();
  const handleTabSelect = (key) => {
    setActiveKey(key);
  };
  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("dark");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const controls = useAnimation();
  useEffect(() => {
    if (sectionViewCheck) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, sectionViewCheck]);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });



  useTransform(scrollYProgress, (pos) => {
    if (sectionViewCheck) {
      return pos > 0.79 ? toggleTheme("dark") : toggleTheme("light");
    }
  });

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  const [light, setLight] = useState('');

  return (
    <>
      <motion.div
        ref={targetRef}
        animate={controls}
        initial={{ backgroundColor: "#202020" }}
        variants={{
          visible: { backgroundColor: "#F1F2F2" },
          hidden: { backgroundColor: "#202020" },
        }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 60,
          delay: 0.3,
          duration: 1,
        }}
        className="container-fluid other-work  verticial-height  custom-padding section-padding position-relative overflow-hidden"
      >
        <motion.div
          initial={{ height: "0%", width: "0.2%", top: "15%", right: "49%" }}
          whileInView={{ height: "80%" }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 60,
            delay: 1,
            duration: 5,
          }}
          className=" d-lg-block d-none bg-secondary  position-absolute"
        ></motion.div>
        <div className="row">
          <div className="col-lg-12">
            <h2 className="heading-2 px-2">Other Works</h2>
          </div>
        </div>
        <div className="row inline-eighteen">
          <Col md={6} sm={12}>
            <Nav
              className="flex-column  h-100 algin-items-start justify-content-between"
              variant="tabs"
              onSelect={handleTabSelect}
              activeKey={activeKey}
            >
              <Nav.Item onClick={() => setLight('Pull and Bear store tour')} style={{ textDecoration: light === 'Pull and Bear store tour' ? 'solid black underline' : undefined }} className="demo mt-4 customhoverjunaid">
                <Nav.Link
                  eventKey="tab1"
                  onMouseOver={() => handleTabSelect("tab1")}
                  onClick={() => navigate("/pullandbear")}
                >
                  Pull and Bear store tour
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Calliope')} style={{ textDecoration: light === 'Calliope' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab2"
                  onMouseOver={() => handleTabSelect("tab2")}
                  onClick={() => navigate("/calliope")}
                >
                  Calliope
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Superga')} style={{ textDecoration: light === 'Superga' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab3"
                  onMouseOver={() => handleTabSelect("tab3")}
                  onClick={() => navigate("/superga")}
                >
                  Superga
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Durex')} style={{ textDecoration: light === 'Durex' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab4"
                  onMouseOver={() => handleTabSelect("tab4")}
                  onClick={() => navigate("/durex")}
                >
                  Durex
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Charlotte Tilbury')} style={{ textDecoration: light === 'Charlotte Tilbury' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab5"
                  onMouseOver={() => handleTabSelect("tab5")}
                  onClick={() => navigate("/riverisland")}
                >
                  River Island
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight(' About You')} style={{ textDecoration: light === ' About You' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab6"
                  onMouseOver={() => handleTabSelect("tab6")}
                  onClick={() => navigate("/aboutyou")}
                >
                  About You
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Gaelle Positano experience')} style={{ textDecoration: light === 'Gaelle Positano experience' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab7"
                  onMouseOver={() => handleTabSelect("tab7")}
                  onClick={() => navigate("/gaellepositanoexperience")}
                >
                  Gaelle Positano experience
                </Nav.Link>
              </Nav.Item>
              <Nav.Item onClick={() => setLight('Gaelle Positano experience')} style={{ textDecoration: light === 'Gaelle Positano experience' ? 'solid black underline' : undefined }} className="demo customhoverjunaid">
                <Nav.Link
                  eventKey="tab8"
                  onMouseOver={() => handleTabSelect("tab8")}
                  onClick={() => navigate("/pullandbearexp")}
                >
                  Pull and Bear Primavera Sound

                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={6} sm={12} className="  d-lg-block d-none">
            <Tab.Container id="side-nav-tab"
              activeKey={activeKey}
            >
              <Tab.Content>
                <Tab.Pane eventKey="tab1">
                  <img
                    src={pullandbearpreview}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "15%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <img
                    src={calliope}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "18%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab3">
                  <img
                    src={superga}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "20%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab4">
                  <img
                    src={durax}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "25%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab5">
                  <img
                    src={riverisland}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "30%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab6">
                  <img
                    src={aboutyou}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "35%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab7">
                  <img
                    src={gaelleexp}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "45%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab8">
                  <img
                    src={ImagePullandBearexperience}
                    className="position-absolute  d-lg-block d-none  "
                    style={{
                      top: "45%",
                      right: "3%",
                    }}
                    alt=""
                  />
                </Tab.Pane>
                {/* Add more tab content as needed */}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </div>
      </motion.div>
    </>
  );
};

export default ProjectOtherWork;

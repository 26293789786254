import React from "react";
import ProjectBanner from "../components/ProjectBanner";
import ProjectOtherWork from "../components/ProjectOtherWork";
import Contact from "../components/Contact";
import AllProjects from "../components/AllProjects";
import AllProjectsMobile from "../components/AllProjectsMobile";

const Project = () => {
  return (
    <>
      <ProjectBanner />
      <AllProjects />
      <AllProjectsMobile />
      <ProjectOtherWork />
      <Contact />
    </>
  );
};

export default Project;

import React, { useEffect, useRef } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import downArrow from "../assets/img/icon/downarrow.svg";
import { useTheme } from "../Context/useTheme";
import { useMediaQuery } from "../hooks/useMediaQuery";
import HeadingAnimation from "./HeadingAnimation";

const Achieve = () => {
  const mobileView = useMediaQuery("(max-width: 480px)");
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  useTransform(scrollYProgress, (pos) => {
    return pos < 1 && pos > 0 ? toggleTheme("dark") : toggleTheme("light");
  });
  useEffect(() => {
    if (sectionViewCheck) {
      toggleisHome(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  const hrOpacity = useTransform(scrollYProgress, [0, 0.8], [0, 1]);
  var y = useParallax(scrollYProgress, mobileView ? -50 : -150);
  // var mobileY = useParallax(scrollYProgress, 37);

  // const mobileY = useParallax(scrollYProgress, -150);

  return (
    <>
      <div ref={targetRef} className="container-fluid  achieve  ">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 d-flex align-items-start justify-content-center bg-light">
            <div className={`bg-heading pr-11`}>Achieve IT</div>
          </div>
        </div>
        <motion.div
          className="row  coverup"
          style={{
            y,
          }}
        >
          <div className="d-flex justify-content-evenly align-items-start py-5 h-0">
            <img src={downArrow} className="img-fluid mobile-svg-size" alt="" />
            <button
              type="button"
              class="btn btn-outline-secondary btn-lg rounded-pill scroll-down-btn"
            >
              Scroll down
            </button>
            <img src={downArrow} className="img-fluid mobile-svg-size" alt="" />
          </div>
          <div className="row custom-padding  ">
            <div className="col-12 col-md-12 col-lg-12">
              <HeadingAnimation
                elementType="h1"
                classes="text-white text-uppercase "
              >
                <span className="text1 d-md-block fw-normal d-flex align-items-end justify-content-start">
                  <span className="let-start inline-eight">LET’S START </span>
                  <p className="text-secondary  Achieve-text align-self-center  align-items-around justify-content-start d-md-block d-lg-inline-flex d-none">
                    <span className="text-capitalize mx-3 inline-nine">
                      Together
                    </span>
                    <motion.hr
                      className="w-50 d-lg-block d-none"
                      style={{
                        opacity: hrOpacity,
                        marginTop: "80px",
                      }}
                    />
                  </p>
                </span>
                <span className="font-antonio d-block fw-bolder something inline-ten">
                  {" "}
                  SOMETHING{" "}
                  <span className="text-primary  font-antonio "> GREAT</span>
                </span>

                <div
                  ref={elementRef}
                  className={`mt-3 ${isInView ? "heading-animation" : ""}`}
                >
                  <span className="font-telegraf fw-normal  every ">
                    EVERY{" "}
                  </span>
                  <span className="every font-antonio">
                    {" "}
                    SINGLE DAY<span className="d-none d-md-inline">.</span>
                  </span>
                </div>
              </HeadingAnimation>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.4,
                duration: 0.5,
              }}
              className="d-flex justify-content-evenly align-items-start pb-5"
            >
              <button
                type="button"
                class="btn btn-outline-secondary mt-5  btn-lg rounded-pill text-uppercase mobile-btn-font"
              >
                We aim to achieve excellence
              </button>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Achieve;
function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TalentBanner from "../components/TalentBanner";
import Contact from "../components/Contact";
import TalentSlider from "../components/TalentSlider";

const Talent = () => {
  const location = useLocation();
  useEffect(() => {

    window.history.scrollRestoration = "manual"
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  return (
    <>
      <TalentBanner />
      <TalentSlider />
      <Contact />
    </>
  );
};

export default Talent;

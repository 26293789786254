import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTheme } from "../Context/useTheme";
import Stars from "../components/Stars";
import { useTypewriter } from "react-simple-typewriter";

const HomeBannerTwo = () => {
  const targetRef = useRef(null);
  const [isFilled, setIsFilled] = useState(false);
  const [text] = useTypewriter({
    words: ["Shape Your"],
    loop: 1,
    delaySpeed: 1000,
  });
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start start", "end end"],
  });

  const opacity = useTransform(scrollYProgress, [0.98, 1], [1, 0]);
  const opacityh1 = useTransform(scrollYProgress, [0.95, 1], [1, 0]);

  const hrOpacity = useTransform(scrollYProgress, [0, 0.9], [0, 1]);
  const scale = useTransform(scrollYProgress, [0.3, 1], [1, 0]);

  const translateX = useTransform(scrollYProgress, [0, 1], [0, -300]);
  const translateY = useTransform(scrollYProgress, [0.3, 1], [0, -700]);
  const ShowTextOpacity = useTransform(scrollYProgress, (pos) => {
    return pos < 0.4 ? "block" : "none";
  });
  const ShowTextTransfrom = useTransform(scrollYProgress, [0.3, 0.4], [1, 0]);
  const position = useTransform(scrollYProgress, (pos) => {
    return pos > 1 ? "relative" : "fixed";
  });
  const display = useTransform(scrollYProgress, (pos) => {
    return pos < 1 ? "block" : "none";
  });

  const { toggleTheme, toggleisHome } = useTheme();

  useTransform(scrollYProgress, (pos) => {
    if (pos < 1) {
      toggleisHome(true);
      toggleTheme("light");
    } else {
      toggleisHome(false);
      toggleTheme("light");
    }
  });
  useEffect(() => {
    toggleTheme("light");
    toggleisHome(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleScroll = () => {
    const scrollThreshold = 10;
    const element = document.getElementById("stroked-identity");

    if (element) {
      const scrollY = window.scrollY || window.pageYOffset;

      if (scrollY > scrollThreshold) {
        element.classList.remove("stroked-identity");
        element.classList.add("stroked-identity-fill");
        setIsFilled(true);
      } else {
        setIsFilled(false);

        element.classList.remove("stroked-identity-fill");
        element.classList.add("stroked-identity");
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <motion.div
        ref={targetRef}
        className="banner-section container-fluid custom-padding bg-dark "
      >
        <motion.div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          <Stars />
        </motion.div>
        <motion.div style={{}} className="row container mt-5">
          <div className="col-12 col-md-12 col-lg-12  ">
            <motion.div
              // style={{ display: ShowTextOpacity, }}
              style={{ scaleY: ShowTextTransfrom, transformOrigin: "top" }}
              initial={{ opacity: 0, y: 10, scaleY: 1 }}
              viewport={{ once: true }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.5,
                  duration: text.length * 2,
                },
              }}
              className="shape-text"
            >
              {text}
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 2, duration: 2 } }}
              style={{
                position,
                opacity,
                display: display,
              }}
              className="home-dynamics"
            >
              <motion.h1
                className="text-white   "
                initial={{ position: "relative", opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 2, duration: 2 } }}
                style={{
                  // scale,
                  // x: translateX,
                  y: isFilled ? translateY : 0,
                  opacity: opacityh1,
                }}
              >
                <div className="col-md-12    ">
                  <div className="stroked-identity-wrapper ">
                    <div
                      id="stroked-identity"
                      className="stroked-identity "
                      data-content="IDENTITY"
                    >
                      IDENTITY
                    </div>
                  </div>
                </div>
              </motion.h1>

              <div>
                <Button
                  variant="outline-light"
                  className="rounded-pill btn-lg premium-btn"
                  onClick={() => navigate("/contact")}
                >
                  Go Premium
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                      stroke="#F1F2F2"
                      stroke-width="2"
                    />
                  </svg>
                </Button>
              </div>
              <span className="text-white float-lg-end float-start mt-5 d-flex algin-self-around position-relative text-banner ">
                <p className="">
                  we choose the{" "}
                  <span
                    className={`${isFilled ? "text-primary" : ""} `}
                    style={{ display: "contents" }}
                  >
                    person
                  </span>{" "}
                  before the talent
                </p>
                {isFilled && (
                  <motion.hr
                    initial={{ width: "0%" }}
                    whileInView={{ width: "100%" }}
                    transition={{
                      type: "spring",
                      stiffness: 70,
                      damping: 40,
                      delay: 0.4,
                      duration: 3,
                    }}
                  />
                )}
              </span>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default HomeBannerTwo;

import CookieConsent from "react-cookie-consent";

const CookieModal = () => {
  const buttonStyle = {
    background: "transparent",
    color: "#FFF",
    fontfamily: "Telegraf",
    fontsize: "24px",
    fontstyle: "normal",
    fontweight: "500",
    lineheight: "normal",
    display: "flex",
    padding: "18px 36px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "30px",
    border: " 1px solid #FFF"
  }
  const DeclinebuttonStyle = {
    background: "transparent",
    color: "#FFF",
    fontfamily: "Telegraf",
    fontsize: "24px",
    fontstyle: "normal",
    fontweight: "500",
    lineheight: "normal",

  }
  const ModalStyle = {
    
  }

  return (
    <CookieConsent
      // location="bottom"
      flipButtons
      enableDeclineButton
      contentClasses="cookie-content my-auto"
      buttonText="Accept "
      declineButtonText="Disable cookies"
      cookieName="myAwesomeCookieName2"
      expires={150}
      buttonStyle={buttonStyle}
      declineButtonStyle={DeclinebuttonStyle}
      debug
      style={ModalStyle}
      buttonWrapperClasses="cookie-button-wrapper"
    >
      This website uses cookies, including third-party cookies, to enhance your experience and provide services in line with your preferences.
    </CookieConsent>
  )
}

export default CookieModal
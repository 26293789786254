import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import { useEffect, useState } from "react";
import Lenis from "@studio-freight/lenis";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Talent from "./pages/Talent";
import WhatweDo from "./pages/WhatWeDo";
import { ThemeProvider } from "./Context/useTheme";
import Project from "./pages/Project";
import LoadingScreen from "./components/LoadingScreen";
import CookieModal from "./components/CookieModal";
import Furla from "./pages/Furla";
import Aboutyou from "./pages/Aboutyou";
import Calliope from "./pages/Calliope";
import CharlotteTilbury from "./pages/CharlotteTilbury";
import Durex from "./pages/Durex";
import GaelleFashionWeek from "./pages/GaelleFashionweek";
import GaellePositanoExperience from "./pages/GaellePositanoexperience";
import Guess from "./pages/Guess";
import Mevlani from "./pages/Mevlani";
import PullBear from "./pages/Pull&bear";
import PullandBearExp from "./pages/PullandBearExp";
import RiverIsland from "./pages/RiverIsland";
import Superga from "./pages/Superga";
import Esteelauder from "./pages/Esteelauder";
import Puma from "./pages/Puma";
import ProjectFilterPage from "./pages/ProjectFilterPage";

function App() {
  const [isLight] = useState(false);
  useEffect(() => {
    const lenis = new Lenis({
      smoothWheel: true,
      smoothTouch: true,
      syncTouch: true,
      lerp: 0.05,
      // duration: 0.1,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    window.history.scrollRestoration = "manual";
    requestAnimationFrame(raf);
  }, []);
  const [showSplash, setShowSplash] = useState(true);

  return (
    <>
      {showSplash ? (
        <LoadingScreen setLoader={setShowSplash} />
      ) : (
        <ThemeProvider>
          <main>
            <Router>
              <Header isLight={isLight} />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/Talent" element={<Talent />} />
                  <Route path="/whatwedo" element={<WhatweDo />} />
                  <Route path="/project" element={<Project />} />
                  <Route
                    path="/project/influencer"
                    element={<ProjectFilterPage />}
                  />
                  <Route
                    path="/project/content"
                    element={<ProjectFilterPage />}
                  />
                  <Route
                    path="/project/production"
                    element={<ProjectFilterPage />}
                  />
                  <Route path="/furla" element={<Furla />} />
                  <Route path="/aboutyou" element={<Aboutyou />} />
                  <Route path="/calliope" element={<Calliope />} />
                  <Route
                    path="/charlottetilbury"
                    element={<CharlotteTilbury />}
                  />
                  <Route path="/durex" element={<Durex />} />
                  <Route
                    path="/gaellefashionweek"
                    element={<GaelleFashionWeek />}
                  />
                  <Route
                    path="/gaellepositanoexperience"
                    element={<GaellePositanoExperience />}
                  />
                  <Route path="/guess" element={<Guess />} />
                  <Route path="/mevlani" element={<Mevlani />} />
                  <Route path="/pullandbear" element={<PullBear />} />
                  <Route path="/pullandbearexp" element={<PullandBearExp />} />
                  <Route path="/riverisland" element={<RiverIsland />} />
                  <Route path="/superga" element={<Superga />} />
                  <Route path="/estee" element={<Esteelauder />} />
                  <Route path="/puma" element={<Puma />} />
                </Routes>
              </Suspense>
            </Router>
          </main>
          <CookieModal />
        </ThemeProvider>
      )}
    </>
  );
}

export default App;

// ThemeContext.js
import React, { createContext, useContext, useState } from "react";
import { useMediaQuery } from "../hooks/useMediaQuery";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const mobileView=useMediaQuery('(max-width: 480px)');
  const [theme, setTheme] = useState("light"); 
  const [isHome, setIsHome] = useState(true); 

  const toggleTheme = (color) => {
    if(mobileView){
      setTheme("light")
    }
    else{
      
      setTheme(color);
    }
  };
  const toggleisHome = (color) => {
    if(mobileView){
      setIsHome(false);
    }else{

      setIsHome(color);
    }
  };
  console.log(theme,isHome ,mobileView ,"curreent")
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, isHome, toggleisHome }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

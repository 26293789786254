export default function checkFileType(filePath) {
    if(!filePath){
        return 'Unknown';
    }
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const videoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv','.webm'];

    // Get the file extension
    const fileExtension = filePath.toLowerCase().split('.').pop();

    if (imageExtensions.includes(`.${fileExtension}`)) {
        return 'Image';
    } else if (videoExtensions.includes(`.${fileExtension}`)) {
        return 'Video';
    } else {
        return 'Unknown';
    }
}
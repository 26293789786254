import { Link, useNavigate } from "react-router-dom";
import { motion, useScroll, useTransform } from "framer-motion";
import Guess from "../assets/img/project/mobileGuess.png";
import lancome from "../assets/img/project/mobilelancome.png";
import Gaelle from "../assets/img/project/mobileImage Gaelle fashion week.png";
import estee from "../assets/img/project/mobile estee.png";
import puma from "../assets/img/project/puma.png";
import charlotte from "../assets/img/project/image Charlottemobile.png";
import { useRef } from "react";
const AllProjectsMobile = () => {
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const check = useTransform(scrollYProgress, (pos) => {
    console.log(pos, "homepos");
  });

  const image1y = useTransform(scrollYProgress, [0.3, 0.6], [1, 1000]);
  const image1opacity = useTransform(scrollYProgress, [0.3, 0.6], [1, 0]);
  const image1scale = useTransform(scrollYProgress, [0.3, 0.6], [1, 0]);
  const image2y = useTransform(scrollYProgress, [0.3, 0.6], [-500, 1]);
  const image2opacity = useTransform(scrollYProgress, [0.3, 0.6], [0, 1]);
  const image2scale = useTransform(scrollYProgress, [0.3, 0.6], [0, 1]);

  return (
    <>
      {/* mobile */}
      <div
        ref={targetRef}
        className="bg-dark  px-4  d-lg-none d-block container-fluid"
      >
        <div className="row">
          <div className=" col-md-12 d-flex overflow-scroll w-100 justify-content-start  align-items-center mt-5 touch-scroll ">
            <Link className=" text-white pe-3 text-nowrap p text-decoration-none d-block ">
              All
            </Link>
            <Link
              to="/project/influencer"
              className="p text-white pe-3 text-nowrap text-decoration-none d-block "
            >
              {" "}
              Influencer experience
            </Link>
            <Link
              to="/project/content"
              className="p text-white pe-3 text-nowrap text-decoration-none d-block"
            >
              Content creation
            </Link>
            <Link
              to="/project/production"
              className="p text-white pe-3 text-nowrap text-decoration-none d-block"
            >
              Production
            </Link>
          </div>
        </div>
        <div className="row mt-5 ">
          <div
            className=" col-sm-8 col-8"
            onClick={() => navigate("/guess")}
          >
            <div className="row project-mobile-border  bg-white hover-image rounded-5 ">
              <div className=" col-sm-12    ">
                <h1 className="project-heading text-right pt-3">Guess</h1>
                <p className="project-subheading text-right">
                  Content creation{" "}
                </p>
              </div>
              <div className="col-md-10 g-0">
                <motion.img
                  style={{
                    y: image1y,
                    opacity: image1opacity,
                    scale: image1scale,
                  }}
                  src={Guess}
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
              {/* <div className="overlay-chnage">
                                <button onClick={() => navigate('/furla')} className="text-change px-3 py-2 px-md-5 py-md-3">
                                    See more
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="row mt-5 ">
          <div
            className=" col-sm-8 col-8  ms-auto  allProject-img2  align-self-end "
            onClick={() => navigate("/furla")}
          >
            <div className="row project-mobile-border  bg-white hover-image rounded-5 ">
              <div className=" col-sm-12    ">
                <h1 className="project-heading text-right pt-3">Lancome</h1>
                <p className="project-subheading text-right">
                  Content Creation
                </p>
              </div>
              <div className="col-md-10 g-0">
                <motion.img
                  style={{
                    y: image1y,
                    opacity: image1opacity,
                    scale: image1scale,
                  }}
                  src={lancome}
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
              {/* <div className="overlay-chnage">
                                <button onClick={() => navigate('/furla')} className="text-change px-3 py-2 px-md-5 py-md-3">
                                    See more
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div
            className="col-sm-12 col-lg-12   allProject-img3   hover-image   "
            onClick={() => navigate("/estee")}
          >
            <div className="row  bg-white project-mobile-border rounded-5 ">
              <div className="col-sm-12 col-sm-12 g-0  ">
                <motion.img
                  style={{
                    y: image1y,
                    opacity: image1opacity,
                    scale: image1scale,
                  }}
                  src={estee}
                  className="img-fluid w-100  "
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-sm-12 align-self-center">
                <h1 className="project-heading text-center pt-3">
                  Estee Lauder
                </h1>
                <p className="project-subheading text-center">
                  Content Creation
                </p>
                {/* <div className="overlay-chnage">
                                    <button onClick={() => navigate('/estee')}  className="text-change px-3 py-2 px-md-5 py-md-3">
                                        See more
                                    </button>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div
            className=" col-12 col-sm-12  allProject-img4 mt-4 hover-image"
            onClick={() => navigate("/gaellefashionweek")}
          >
            <div className="row project-mobile-border  bg-white   rounded-5">
              <div className=" col-sm-6 col-6   align-self-center ">
                <h1 className="project-heading text-center pt-3">
                  Gaelle Paris
                </h1>
                <p className="project-subheading text-center">
                  Brand Experience
                </p>
              </div>
              <div className="col-sm-6 col-6 g-0">
                <motion.img
                  style={{
                    y: image2y,
                    opacity: image2opacity,
                    scale: image2scale,
                  }}
                  src={Gaelle}
                  className="img-fluid w-100  w-100"
                  alt=""
                />
              </div>
              {/* <div className="overlay-chnage">
                                <button onClick={() => navigate('/gaellefashionweek')} className="text-change px-3 py-2 px-md-5 py-md-3">
                                    See more
                                </button>
                            </div> */}
            </div>
          </div>
        </div>
        <div className="row   mt-5">
          <div
            className=" col-sm-8  col-8 ms-auto  allProject-img6 "
            onClick={() => navigate("/charlottetilbury")}
          >
            <div className="row project-mobile-border  bg-white hover-image rounded-5 inline-four ">
              <div className="col-sm-12 col-12 g-0 ">
                <motion.img
                  style={{
                    y: image2y,
                    opacity: image2opacity,
                    scale: image2scale,
                  }}
                  src={charlotte}
                  className="img-fluid w-100  w-100 rounded-top-5 "
                  alt=""
                />
              </div>
              <div className=" col-sm-12  col-sm12  text-center py-3   ">
                <h1 className="project-heading text-center pt-3">
                  Charlotte Tilbury
                </h1>
                <p className="project-subheading text-center">
                  Brand Experience
                </p>
              </div>
              {/* <div className="overlay-chnage">
                                <button onClick={() => navigate('/charlottetilbury')} className="text-change px-3 py-2 px-md-5 py-md-3">
                                    See more
                                </button>
                            </div> */}
            </div>
          </div>
          <div className="col-1"></div>
        </div>

        <div className="row mt-5 ">
          <div className="col-sm-2 col-2"></div>
          <div
            className=" col-8 align-self-end   allProject-img5 g-0    me-3 pb-5 "
            onClick={() => navigate("/puma")}
          >
            <div className="row g-0   mobile-view-project-card project-mobile-border hover-image ">
              <div className="col-sm-12 g-0  ">
                <img
                  src={puma}
                  className="img-fluid w-100 w-100 rounded-top-5"
                  alt=""
                />
              </div>
              {/* <div className="overlay-chnage">
                                <button onClick={() => navigate('/puma')} className="text-change px-3 py-2 px-md-5 py-md-3">
                                    See more
                                </button>
                            </div> */}
              <div className="col-sm-12 text-center py-3 bg-white rounded-bottom-5">
                <h1 className="project-heading text-center pt-3">Puma</h1>
                <p className="project-subheading text-center">
                  Content Creation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProjectsMobile;

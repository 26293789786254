import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import ResumeSingleVideoSection from '../components/pagesComponents/ResumeSingleVideoSection'
import ThreePictureDescriptionSection from '../components/pagesComponents/ThreePictureDescriptionSection'
import video from "../assets/video/riverisland/1.MP4"
import image1 from "../assets/video/riverisland/1.jpeg"
import image2 from "../assets/video/riverisland/2.jpeg"
import image3 from "../assets/video/riverisland/13.jpeg"
import image4 from "../assets/video/riverisland/16.jpeg"
import image5 from "../assets/video/riverisland/5.jpeg"
import Contact from '../components/Contact'





const RiverIsland = () => {
  const taskdesc="We collaborated with River Island for a content creation project."
  const task =[ "ADVERTISING","CONTENT CREATION" ,"INFLUENCER MARKETING"  ]
  const resumedesc ="The creator produced content for the brand for a total amount of 1 sponsored videos and 10 pictures."
  return (
    <>
    <TwoVideoBanner resource1={image2} resource2={image1} title={"River Island"}  />
    <OurTask desc={taskdesc} task={task} heading={"the project"}   />
    <ResumeSingleVideoSection  desc={resumedesc} resource1={video} secondVideo={false}  righttext={"THE VIDEO"} isLast={false} heading="In details"/>
    <ThreePictureDescriptionSection  resource1={image3} resource2={image4} resource3={image5} isLast={true}/>
    <Contact />

    </>
  )
}

export default RiverIsland;
import React from 'react'
import TalentSliderMobile from './TalentSliderMobile'
import TalentSliderDesktop from './TalentSliderDesktop'

 const TalentSlider = () => {
  return (
   <>
    <TalentSliderMobile />
    <TalentSliderDesktop />

   </>
  )
}

export default TalentSlider

import React, { useEffect, useRef, useState } from "react";
import downArrow from "../assets/img/icon/downarrow.svg";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Image0 from "../assets/img/collage/00.png";
import Image1 from "../assets/img/collage/01.png";
import Image2 from "../assets/img/collage/02.png";
import Image10 from "../assets/img/collage/10.png";
import Image11 from "../assets/img/collage/11.png";
import Image12 from "../assets/img/collage/12.png";
import Image20 from "../assets/img/collage/20.png";
import Image21 from "../assets/img/collage/21.png";
import Image22 from "../assets/img/collage/22.png";
import hairs from "../assets/img/collage/hairs.png";
import lips from "../assets/img/collage/lips.png";
import necklace from "../assets/img/collage/necklace.png";

import { useTheme } from "../Context/useTheme";
import { useMediaQuery } from "../hooks/useMediaQuery";
import HeadingAnimation from "./HeadingAnimation";
const HomeConnecting = () => {
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  console.log(isInView, "sufyanisInView");
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  useTransform(scrollYProgress, (pos) => {
    if (pos < 1 && pos > 0) {
      toggleisHome(false);
      toggleTheme("light");
    }
  });

  console.log(scrollYProgress, "scrollYProgress");
  return (
    <>
      <div
        className="container-fluid bg-dark Home-Connecting custom-padding pt-2"
        ref={targetRef}
      >
        <div ref={elementRef} className="row mb-sm-0 mb-md-5">
          <div className="col-12 col-md-12 col-lg-12">
            <h1 className="text-white ">
              <HeadingAnimation
                elementType={"span"}
                classes="text1 d-md-flex d-sm-block align-items-end justify-content-start text-uppercase connecting-text"
              >
                <span className=" text-uppercase inline-thirteen d-sm-block ">
                  Connecting
                </span>
                <p
                  className={`text-secondary   beside-text text-lowercase mobile-view-span connecting-p-text  d-lg-block d-none ${
                    isInView ? "heading-animation" : ""
                  }`}
                >
                  Specialized in the fashion and beauty sector.
                </p>
              </HeadingAnimation>
              <HeadingAnimation
                elementType={"span"}
                classes="font-antonio d-block fw-bolder text-uppercase talented-block"
              >
                {" "}
                <span className="text-primary connecting-talented-text  font-antonio  fw-bolder text-uppercase d-md-inline d-block font-55  position-relative connecting-text-2">
                  talented
                  <p
                    className={`text-secondary   beside-text text-lowercase mobile-view-span connecting-p-text   d-lg-none d-block ${
                      isInView ? "heading-animation" : ""
                    }`}
                  >
                    Specialized in the fashion and beauty sector.
                  </p>
                </span>{" "}
                <span className="connecting-text-3 inline-seven pt-3 pd-md-0 d-md-inline d-block ">
                  individuals
                </span>
              </HeadingAnimation>
              <HeadingAnimation
                elementType={"span"}
                classes="font-telegraf  text-uppercase inline-thirteen block-text mobile-pt-10 d-block d-md-inline p-0 font-64"
              >
                with{" "}
              </HeadingAnimation>
              <HeadingAnimation
                elementType={"span"}
                classes={`font-55 pt-2 pt-md-0 d-block d-md-inline font-antonio  fw-bolder text-uppercase inline-fourteen mobile-brand-color pb-4 pb-md-0 py-2 py-md-0 connecting-text-4 ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                Brand identity
              </HeadingAnimation>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-3 col-12 ">
            <div className="row">
              <div className=" col-md-8 col-8">
                <p className="text-white  mt-2">
                  We enable people and businesses to{" "}
                  <span className="text-primary ">
                    extend their digital identity
                  </span>{" "}
                  through the power of content creation.
                </p>
              </div>
              <div className=" col-4 arrow-icon-secont-two d-block d-lg-none">
                <img src={downArrow} alt="" className="img-fluid" />{" "}
              </div>
            </div>
          </div>
          <div className="col-md-12  col-lg-6  col-12 ">
            <ImageSection />
          </div>

          <div className="col-md-12 col-lg-3 col-8 d-flex flex-column  justify-content-between  align-items-center pb-10">
            <div className=" d-lg-block d-none col-3 ">
              <img src={downArrow} alt="" className="img-fluid" />{" "}
            </div>
            <p className="text-white pt-45" style={{ marginBottom: "40px" }}>
              We provide unique and engaging content meant to increase brand
              awareness and{" "}
              <span className="text-primary ">create an impact</span> on social
              media.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeConnecting;

export const ImageSection = () => {
  const LaptopView = useMediaQuery("(max-width: 1350px)");
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const images = [
    {
      id: 1,
      url: Image0,
      start: { x: "0vw", y: "-20vw" },
      style: {},
      col: 4,
    },

    {
      id: 2,
      url: Image10,
      start: { x: "-50vw", y: "-20vw" },
      col: 4,
    },
    {
      id: 3,
      url: Image20,
      start: { x: "20vw", y: "-20vw" },
      col: 4,
    },
    {
      id: 1,
      url: Image1,
      start: { x: "-10vw", y: "-40vw" },
      col: 4,
    },
    {
      id: 4,
      url: Image11,
      start: { x: "-20vw", y: "-30vw" },
      col: 4,
    },
    {
      id: 6,
      url: Image21,
      start: { x: "-20vw", y: "40vw" },
      col: 4,
    },

    {
      id: 7,
      url: Image2,
      start: { x: 0, y: "-20vw" },
      col: 4,
    },
    {
      id: 9,
      url: Image12,
      start: { x: 0, y: "-20vw" },
      col: 4,
    },
    {
      id: 5,
      url: Image22,
      start: { x: 0, y: "-20vw" },
      col: 4,
    },

    // Add more images as needed
  ];
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll();

  // const image1y = useTransform(scrollYProgress, [0.19, 0.273], [0, 1300]);
  // const image1Scale = useTransform(scrollYProgress, [0.19, 0.273], [1, 0.5]);
  const image1Opacity = useTransform(
    scrollYProgress,
    [0.17, 0.172, LaptopView ? 0.221 : 0.235, LaptopView ? 0.228 : 0.237],
    [0, 1, 1, 0]
  );
  // const image1Display = useTransform(scrollYProgress, (pos) => {
  //return pos > 0.238 ? "none" : "block";
  // });
  // const hairsx = useTransform(scrollYProgress, [0.2, 0.273], [0, -40]);
  // const lips1y = useTransform(scrollYProgress, [0.2, 0.273], [0, 1500]);
  // const lips1Scale = useTransform(scrollYProgress, [0.2, 0.273], [1, 0.5]);
  // const lips1Opacity = useTransform(
  //scrollYProgress,
  //[0.2, 0.182, LaptopView ? 0.2165 : 0.22, LaptopView ? 0.217 : 0.221],
  //[0, 1, 1, 0]
  // );
  // const lipsx = useTransform(scrollYProgress, [0.2, 0.273], [0, 950]);
  // const lipsDisplay = useTransform(scrollYProgress, (pos) => {
  //return pos > 0.225 ? "none" : "block";
  // });
  // const necklace1y = useTransform(scrollYProgress, [0.21, 0.273], [0, 1600]);
  // const necklace1Scale = useTransform(scrollYProgress, [0.21, 0.273], [1, 0.9]);
  // const necklace1Opacity = useTransform(
  //scrollYProgress,
  //[0.21, 0.192, LaptopView ? 0.221 : 0.227, LaptopView ? 0.222 : 0.229],
  //[0, 1, 1, 0]
  // );
  // const necklacex = useTransform(scrollYProgress, [0.21, 0.273], [0, -900]);
  // const necklaceDisplay = useTransform(scrollYProgress, (pos) => {
  //return pos > 0.23 ? "none" : "block";
  // });
  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    // Check if the section is in view and the state is not already set to true
    if (isInView === false && sectionViewCheck) {
      // Update the state to true
      setIsInView(true);
    }
  }, [sectionViewCheck, isInView]);
  useTransform(scrollYProgress, (pos) => {
    if (pos > 0.275 && isAnimationDone === false) {
      setIsAnimationDone(true);
    }
  });
  console.log(
    isAnimationDone,
    !isAnimationDone ? image1Opacity : 0,
    "isAnimationDone"
  );

  return (
    <div
      className="p-sm-0 p-md-5 mt-sm-0 mt-md-2 row px-md-5 home connecting-images position-relative mx-auto"
      ref={targetRef}
    >
      {/* <motion.img
  src={hairs}
  className={`floating-images ${!isAnimationDone ? "" : "d-none"}`}
  style={{
 position: "absolute",
 height: "30%",
 width: "53%",
 zIndex: 1,
 left: "25%",
 top: "10%",
 y: image1y,
 opacity: image1Opacity,
 scale: image1Scale,
 x: hairsx,
 display: image1Display,
 filter: "grayscale(1)",
  }}
/>
<motion.img
  src={lips}
  className={`floating-images ${!isAnimationDone ? "" : "d-none"}`}
  style={{
 position: "absolute",
 height: "15%",
 width: "17%",
 zIndex: 1,
 top: "57%",
 left: "39%",
 y: lips1y,
 opacity: lips1Opacity,
 scale: lips1Scale,
 x: lipsx,
 display: lipsDisplay,
 filter: "grayscale(1)",
  }}
      />
      <motion.img
        src={necklace}
        className={`floating-images ${!isAnimationDone ? "" : "d-none"}`}
        style={{
          position: "absolute",
          height: "21%",
          width: "22%",
          zIndex: 1,
          top: "71%",
          left: "34%",
          y: necklace1y,
          opacity: necklace1Opacity,
          scale: necklace1Scale,
          display: necklaceDisplay,
          x: necklacex,
          filter: "grayscale(1)",
        }}
      /> */}

      {isInView &&
        images.map((image, index) => {
          // const imageCount = images.length;
          let startPositionX = 0;
          let startPositionY = 0;

          if (index === 0) {
            startPositionX = "-20vh";
            startPositionY = "-20vh";
          } else if (index === 1) {
            startPositionX = "-10vh";
            startPositionY = "-40vh";
          } else if (index === 2) {
            startPositionX = "20vh";
            startPositionY = "-20vh";
          } else if (index === 3) {
            startPositionX = "-50vh";
            startPositionY = "-40vh";
          } else if (index === 4) {
            startPositionX = "-20vh";
            startPositionY = "-30vh";
          } else if (index === 5) {
            startPositionX = " 20vh";
            startPositionY = "-30vh";
          } else if (index === 6) {
            startPositionX = "-20vh";
            startPositionY = " 40vh";
          } else if (index === 7) {
            startPositionX = "-50vh";
            startPositionY = "40vh";
          } else if (index === 8) {
            startPositionX = " 20vh";
            startPositionY = "40vh";
          }

          return (
            <div className={`col-${image.col} g-0`} key={image.id}>
              <motion.img
                src={image.url}
                style={image.style}
                className="img-fluid w-100"
                viewport={{ once: true }}
                initial={{ x: startPositionX, y: startPositionY, opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeInOut", delay: 0.2 }}
              />
            </div>
          );
        })}
    </div>
  );
};

import React, { useEffect, useRef } from "react";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Project1 from "../assets/img/projects/1.png";
import Project2 from "../assets/img/projects/2.png";
import Project3 from "../assets/img/projects/3.png";
import { useTheme } from "../Context/useTheme";
import { useNavigate } from "react-router-dom";
import HeadingAnimation from "./HeadingAnimation";

const Project = () => {
  const targetRef = useRef(null);

  const sectionViewCheck = useInView(targetRef);

  const { toggleTheme, toggleisHome } = useTheme();

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-70%"]);

  const navigate = useNavigate();
  return (
    <>
      <motion.div
        ref={targetRef}
        className="container-fluid    bg-dark section-padding  position-relative   home-project-slider"
        style={{ height: "300vh" }}
      >
        <div className="position-sticky start-0 top-home-project-slider-10">
          <div className="row ">
            <div className="py-1 pb-5 text-center d-flex align-items-start  justify-content-center">
              <HeadingAnimation
                elementType={"h1"}
                classes="big-heading font-antonio text-light me-2"
              >
                LAST PROJECTS
              </HeadingAnimation>
              <p className="align-self-end  number-text inline-nineteen mt-2">
                03
              </p>
            </div>
          </div>
          <div className="row">
            <p
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 40,
                delay: 0.3,
                duration: 1,
              }}
              className="text-white  w-75   pl-2 d-flex align-items-center"
            >
              Premium
              <br />
              purpose
              <motion.hr className="ms-3 w-100" />
            </p>
          </div>
          <div className="d-block px-3  d-flex  align-items-start overflow-hidden">
            {/* <Swiper
            spaceBetween={50}
            slidesPerView={1.2}
            freeMode={true}
            direction="horizontal"
            mousewheelControl
          >
            <SwiperSlide className="project-slider"  onClick={()=>navigate('/gaellefashionweek')}>
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm   rounded-45 fs-4" onClick={()=>navigate('/gaellefashionweek')}>
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project2} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="project-slider" onClick={()=>navigate('/charlottetilbury')} >
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm fs-4 rounded-45"   onClick={()=>navigate('/charlottetilbury')}  >
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project1} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="project-slider" onClick={()=>navigate('/charlottetilbury')} >
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm  rounded-45 fs-4" onClick={()=>navigate('/guess')}  >
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project3} alt="" />
              </div>
            </SwiperSlide>

          </Swiper> */}
            <motion.div style={{ x }} className="d-flex gap-4 mb-5">
              <div
                className="item w-100 position-relative"
                onClick={() => navigate("/gaellefashionweek")}
              >
                <div className="w-100 h-100">
                  {/* <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm   rounded-45 fs-4" onClick={()=>navigate('/gaellefashionweek')}>
                    See More
                  </button>
                </div> */}
                  <img className="img-fluid  w-100 " src={Project2} alt="" />
                </div>
              </div>

              <div
                className="item w-100 position-relative"
                onClick={() => navigate("/charlottetilbury")}
              >
                <div className="w-100 h-100">
                  {/* <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm fs-4 rounded-45"   onClick={()=>navigate('/charlottetilbury')}  >
                    See More
                  </button>
                </div> */}
                  <img className="img-fluid  w-100 " src={Project1} alt="" />
                </div>
              </div>
              <div
                className="item w-100 position-relative"
                onClick={() => navigate("/guess")}
              >
                <div className="w-100 h-100">
                  {/* <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm  rounded-45 fs-4" onClick={()=>navigate('/guess')}  >
                    See More
                  </button>
                </div> */}
                  <img className="img-fluid  w-100 " src={Project3} alt="" />
                </div>
              </div>
            </motion.div>
          </div>

          <div className="row  pb-5">
            <p onClick={() => navigate("/project")} className="text-white ">
              View all
            </p>
            <div>
              <p className="text-white  col-9 text-justify">
                Each collab is a synergy between our talents and brands,
                crafting authentic and{" "}
                <span className="text-primary ">remarkable campaigns.</span>
              </p>
              <button
                className="rounded-pill btn-sm premium-btn btn btn-outline-light mt-4"
                onClick={() => navigate("/contact")}
              >
                Go Premium
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.5234 4.5C15.5234 5.242 16.2564 6.35 16.9984 7.28C17.9524 8.48 19.0924 9.527 20.3994 10.326C21.3794 10.925 22.5674 11.5 23.5234 11.5M23.5234 11.5C22.5674 11.5 21.3784 12.075 20.3994 12.674C19.0924 13.474 17.9524 14.521 16.9984 15.719C16.2564 16.65 15.5234 17.76 15.5234 18.5M23.5234 11.5H0.998535"
                    stroke="#F1F2F2"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      {/* <section ref={targetRef} className="position-relative  bg-transparent horizontal-crousel container-fluid project  bg-dark section-padding   " style={{height:"300vh"}}>
        
        <div className="position-sticky top-10 d-flex talent-slider-container align-items-start overflow-hidden ">
          <motion.div style={{ x }} className="d-flex gap-5">
          <div className="item w-100 position-relative"  onClick={()=>navigate('/gaellefashionweek')}>
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm   rounded-45 fs-4" onClick={()=>navigate('/gaellefashionweek')}>
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project2} alt="" />
              </div>
            </div>

            <div className="item w-100 position-relative" onClick={()=>navigate('/charlottetilbury')} >
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm fs-4 rounded-45"   onClick={()=>navigate('/charlottetilbury')}  >
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project1} alt="" />
              </div>
            </div>
            <div className="item w-100 position-relative" onClick={()=>navigate('/charlottetilbury')} >
              <div className="w-100 h-100">

                <div className="mobile-project-overlay">
                  <button className="btn px-3 py-1  btn-outline-dark btn-sm  rounded-45 fs-4" onClick={()=>navigate('/guess')}  >
                    See More
                  </button>
                </div>
                <img className="img-fluid  w-100 " src={Project3} alt="" />
              </div>
            </div>

                      
                </motion.div>
            </div>
        </section> */}
    </>
  );
};

export default Project;

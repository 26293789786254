import React, { useEffect, useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import Image1 from "../../assets/img/talentslider/1-1.png";
import Image12 from "../../assets/img/talentslider/1-2.png";
import Image13 from "../../assets/img/talentslider/1-3.png";
import Image14 from "../../assets/img/talentslider/1-4.jpg";
import Image15 from "../../assets/img/talentslider/1-5.jpg";
import Image16 from "../../assets/img/talentslider/1-6.png";

import Image2 from "../../assets/img/talentslider/2-1.png";
import Image22 from "../../assets/img/talentslider/2-2.png";
import Image23 from "../../assets/img/talentslider/2-3.png";
import Image24 from "../../assets/img/talentslider/2-4.jpg";
import Image25 from "../../assets/img/talentslider/2-5.jpg";
import Image26 from "../../assets/img/talentslider/2-6.png";

import Image3 from "../../assets/img/talentslider/3-1.png";
import Image32 from "../../assets/img/talentslider/3-2.png";
import Image33 from "../../assets/img/talentslider/3-3.png";
import Image34 from "../../assets/img/talentslider/3-4.jpg";
import Image35 from "../../assets/img/talentslider/3-5.jpg";

import { useTheme } from "../../Context/useTheme";
import { useInView } from "framer-motion";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Link } from "react-router-dom";

const TalentSliderDesktop = () => {
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();
  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  return (
    <>
      <div
        ref={targetRef}
        className="bg-dark  talent-slider-section g-0 m-0 p-0  d-lg-block"
      >
        <div className="talent-marquee">
          <Marquee speed={200}>
            <span className="text-white me-3">OUR {"      "}</span>

            <span className="text-secondary me-3">PREMIUM {"      "}</span>
            <span className="text-primary me-3">CREATORS {"      "}</span>
          </Marquee>
        </div>
        <div
          className="row g-0  "
          style={{
            // y,
            position: "relative",
            top: "-7vw",
            zIndex: 1,
          }}
        >
          <HorizontalScrollCarousel slideData={slider2} />
          <HorizontalScrollCarousel slideData={slider1} />
          <HorizontalScrollCarousel slideData={slider3} />
        </div>
      </div>
    </>
  );
};

export default TalentSliderDesktop;

const HorizontalScrollCarousel = ({ slideData }) => {
  const targetRef = useRef(null);
  const mobileView = useMediaQuery("(max-width: 600px)");
  const tabletView = useMediaQuery("(min-width: 601px) and (max-width: 992px)");
  const desktopView = useMediaQuery("(min-width: 993px)");

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  let scrollPercent;
  if (mobileView) {
    scrollPercent = "-80%";
  } else if (tabletView) {
    scrollPercent = "-75%";
  } else if (desktopView) {
    scrollPercent = "-50%";
  }

  const x = useTransform(scrollYProgress, [0, 1], ["1%", scrollPercent]);
  const navigate = (url) => {
    window.open(url, "_blank");
  };
  return (
    <section
      ref={targetRef}
      className="position-relative  bg-transparent horizontal-crousel "
      style={{ height: "300vh" }}
    >
      <div className="position-sticky top-10 d-flex talent-slider-container align-items-start overflow-hidden">
        <motion.div style={{ x }} className="d-flex gap-5">
          {slideData.map((e) => {
            return (
              <div
                className="item w-100 position-relative"
                onClick={() => navigate(e.url)}
              >
                <img
                  src={e.img}
                  className="img-fluid rounded-3 w-100 h-100"
                  alt=""
                />
                <div className="content position-absolute  start-0 ps-3">
                  <p className="text-white  font-telegraf m-0">{e.name}</p>
                  <p className="text-white  font-telegraf m-0 fs-6">
                    @{e.url.split("/")[3]}
                  </p>
                </div>
              </div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

const slider1 = [
  {
    img: Image1,
    name: "Caterina Carani",
    url: "https://www.instagram.com/caterinacarani/",
  },

  {
    img: Image12,
    name: "Giulia Tascione",
    url: "https://www.instagram.com/giuliatascione/",
  },
  {
    img: Image13,
    name: "Elena Petrin",
    url: "https://www.instagram.com/elena.petrin/",
  },
  {
    img: Image14,
    name: "Arianna Rinaldi",
    url: "https://www.instagram.com/ariannarinaldii/",
  },
  {
    img: Image15,
    name: "Alice Poletti",
    url: "https://www.instagram.com/aaaaaaalis",
  },
  {
    img: Image16,
    name: "Giulia Testolina",
    url: "https://www.instagram.com/gggggggiiiu/",
  },
];
const slider2 = [
  {
    img: Image2,
    name: "Benedetta Bottin",
    url: "https://www.instagram.com/benedettabottin/ ",
  },

  {
    img: Image22,
    name: "Ludovica Nicolò",
    url: "https://www.instagram.com/ludovicanick/",
  },
  {
    img: Image35,
    name: "Gabriel Nobile",
    url: "https://www.instagram.com/gabrielnobile/ ",
  },
  // {
  //   img: Image23,
  //   name: "Giulia Cappelli",
  //   url: "https://www.instagram.com/giuliicappelli/",
  // },
  {
    img: Image24,
    name: "Senada",
    url: "https://www.instagram.com/s.e.ae/",
  },
  {
    img: Image25,
    name: "Alessandra Belda",
    url: "https://www.instagram.com/alessandrabelda/",
  },
  {
    img: Image26,
    name: "Jos Schenk",
    url: "https://www.instagram.com/jos_schenk/",
  },
];
const slider3 = [
  {
    img: Image3,
    name: "Martina Cau",
    url: "https://www.instagram.com/skinbymarss/ ",
  },

  {
    img: Image32,
    name: "Anastasia Khavy",
    url: "https://www.instagram.com/anastasiia_khavy/",
  },
  {
    img: Image33,
    name: "Serena e Letizia Reginelli",
    url: "https://www.instagram.com/ginger__twins/",
  },
  {
    img: Image34,
    name: "Greta Filippeschi",
    url: "https://www.instagram.com/gretafilippeschi/",
  },
];

import React, { useEffect, useRef } from "react";
import downArrow from "../assets/img/icon/downarrow.svg";
import { useTheme } from "../Context/useTheme";
import { useInView } from "framer-motion";
const ProjectBanner = () => {
  const targetRef = useRef(null);
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <>
      <div
        className="container-fluid bg-dark  custom-padding  project-banner  align-items-center "
        ref={targetRef}
      >
        <div className=" ">
          <div className="row " ref={elementRef}>
            <div className="col-12 col-md-12 col-lg-12">
              <h1
                className="text-white text-uppercase big-heading  font-telegraf "
                style={{ letterSpacing: "normal" }}
              >
                DISCOVER
                <span className=" text-primary   font-antonio  fw-bolder">
                  {" "}
                  STORIES{" "}
                </span>
                of
                <span className="font-antonio fw-bolder"> BRANDS </span>
                <span className="font-telegraf "> THAT </span>
                <span className="font-antonio fw-bolder"> REFLECT </span>
                <span
                  className={`font-telegraf  ${
                    isInView ? "heading-animation" : ""
                  }`}
                >
                  {" "}
                  OUR{" "}
                </span>
                <span
                  className={`font-antonio text-primary fw-bolder f-65 ${
                    isInView ? "heading-animation" : ""
                  }`}
                >
                  {" "}
                  Vision.{" "}
                </span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-10 col-10 ">
              <div className="custom-w-project-benner">
                <p className="text-white text-justify mt-4 mx-auto p-2 p-md-0">
                  Our projects are built{" "}
                  <span className="fw-bolder"> with passion</span>. Find out our
                  latest collaborations.
                </p>
              </div>
            </div>

            {/* <div className="col-md-12 col-lg-3   col-12 "></div> */}
            <div className="col-md-2 col-lg-2 col-2 d-flex justify-content-center align-items-end">
              <img
                src={downArrow}
                alt=""
                className="img-fluid   mx-auto my-auto"
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBanner;

import React from 'react'
import TwoVideoBanner from '../components/pagesComponents/TwoVideoBanner'
import OurTask from '../components/pagesComponents/OurTask'
import TwoVideoSection from '../components/pagesComponents/TwoVideoSection'
import video1 from '../assets/video/esteelauder/1.MP4'
import video2 from '../assets/video/esteelauder/2.MP4'
import video3 from '../assets/video/esteelauder/3.MP4'
import video4 from '../assets/video/esteelauder/4.MP4'
import video5 from '../assets/video/esteelauder/5.MP4'

import StyleInfoSection from '../components/pagesComponents/StyleInfoSection'
import Contact from '../components/Contact'



const Esteelauder = () => {
  const desc="We collaborated with Estee Lauder for a long term partnership with our talent Martina Cau. The project consisted in a content creation and promotional campaign for the brand."
  const task =["ADVERTISING" , "CONTENT CREATION" ,"INFLUENCER MARKETING"  ,"IGC CREATION"]
  const desc2 = "Martina created a total amount of 12 content between reels, tik toks and stories."
  return (
    <>
    <TwoVideoBanner title={"Estee Lauder "} de resource1={video1}  resource2={video2} resource2layout={true}/>
    <OurTask desc={desc}task={task} heading={"In Details"} />
    <StyleInfoSection resource1={video3} resource2={video4} resource3={video5} resource4={video1} desc={desc2} isLast={true} />
    <Contact />

    </>
  )
}

export default Esteelauder
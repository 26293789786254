import React from 'react'
import DescriptionBanner from '../components/pagesComponents/DescriptionBanner'
import VideoSection from '../components/pagesComponents/VideoSection'
import video from "../assets/video/durex/3.MP4"
import video1 from "../assets/video/durex/2.MP4"
import Contact from '../components/Contact'


const Durex = () => {
  const desc = 'We collaborated with Durex for the creation of the campaign "Durex a Luci Accese”. Our talent Arianna Rinaldi has been involved as the protagonist of the campaign.'
  return (
    <>
    <DescriptionBanner desc={desc} video={video}  title={"Durex"} />
    {/* <VideoSection video={video1} /> */}
    <Contact />

    </>
  )
}

export default Durex
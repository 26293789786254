import checkFileType from "../../../utils/CheckFileType"
import Popup from "../../popupanimaitons"
import "./style.css"

const TwoVideoSection = ({ image1, image2  ,isLeft,section2paddingTop}) => {
    return (
        <div className="contianer-fluid bg-lightgreen pt-5 custom-padding  min-vh-100">
            <div className="row mt-5">
                <div className="col-md-12  col-lg-5 col-sm-12 col-12">
                    <Popup>


                    <div className="two-video-wrapper1 mx-auto">
                        <div className='mx-auto d-flex  ratio ratio-4x3'>
                            {
                                checkFileType(image1) === 'Video' ? <video className=' my-auto mx-auto ' src={image1} autoPlay muted style={{ objectFit: "cover" }} /> 
                                : <img className='  ' src={image1} autoPlay muted style={{ objectFit: "cover" }} />

                            }
                        </div>
                    </div>
                    </Popup>
                </div>
                <div className="col-md-12 col-lg-7 col-sm-12 col-12">
                    <Popup>
                    <div className={`two-video-wrapper2  mx-auto ${section2paddingTop?"pt-5":""}`}>
                        <div className={` d-flex   ratio ratio-4x3 rounded  ${isLeft?"float-end":"float-start"}`} >
                        {
                                checkFileType(image2) === 'Video' ? <video className=' my-auto mx-auto ' src={image2} autoPlay muted style={{ objectFit: "cover" }} /> 
                                : <img className='  ' src={image2} autoPlay muted style={{ objectFit: "cover" }} />

                            }
                        </div>
                    </div>
                    </Popup>
                </div>

            </div>
        </div>
    )
}

export default TwoVideoSection
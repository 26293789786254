import { motion, useAnimation, useTransform, useScroll } from "framer-motion";
import './style.css'
import { useRef } from "react";
import checkFileType from "../../../utils/CheckFileType";
import Popup from "../../popupanimaitons";


const StyleInfoSection = ({ resource1, resource2, resource3, resource4 ,desc ,isLast }) => {
    const controls = useAnimation();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"],
    });
    useTransform(scrollYProgress, (pos) => {
        console.log(pos, "task")
        if (pos >= 1 && !isLast) {
            controls.start("end");
        }

        else if (pos < 0.1 && pos > 0) {
            controls.start("initial");
        }
        else if (pos < 0.9 && pos > 0.6) {
            controls.start("start");
        }
    });

    const Varients = {
        initial: {
            opacity: 0,
            y: -200,
            x: -200
        },
        start: {
            opacity: 1,
            y: 0,
            x: 0,

        },
        end: {
            opacity: 0,
            y: 600,
            x: 200,
            transition: { type: "spring", stiffness: 260, damping: 20, }
        }
    }

    return (
        <motion.div
            ref={targetRef}
            className="container-fluid  custom-padding section-padding min-vh-100  d-flex align-items-lg-end style-section-layout-1">

            <div className="row mt-5  d-lg-flex  d-none">
                <div className="col-lg-5 col-md-12 position-relative">
                    <Popup>

                    <div className="style-info-section-wrapper1">
                        <div className="item1  ratio ratio-1x1    rounded">
                            {
                                checkFileType(resource1) === 'Video' ? <video className=' my-auto mx-auto ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>
                        <div className="item2  ratio ratio-1x1    rounded">
                            {
                                checkFileType(resource2) === 'Video' ? <video className=' my-auto mx-auto ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>
                    </div>
                    </Popup>
                </div>
                <div className="col-lg-7 col-md-12">

                    <div className="style-info-section-wrapper2  position-relative">
                        <b className="style">STYLE</b>
                        <Popup>

                        <div className='item1  ratio ratio-1x1    rounded' >
                            {
                                checkFileType(resource3) === 'Video' ? <video className=' my-auto mx-auto ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />

                            }

                        </div>
                      

                        <div className='item2  ratio ratio-1x1    rounded' >

                            {
                                checkFileType(resource4) === 'Video' ? <video className=' my-auto mx-auto ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>
                        </Popup>
                    </div>
                    <div className="row">
                        <div className="col-10 col-lg-6 ">
                            <motion.p className='mt-5  mx-auto image-text d-none d-md-block'
                                animate={controls}
                                variants={Varients}
                            >
                               {desc}
                            </motion.p>
                            <p className='mt-5  mx-auto image-text d-block d-md-none'
                                animate={controls}
                                variants={Varients}
                            >
                                {desc}
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5  d-lg-none  d-flex">
                <div className="col-md-12">

                        <Popup>
                    <div className="style-info-section-wrapper2  position-relative">
                        <b className="style">STYLE</b>

                        <div className='item1  ratio ratio-1x1    rounded' >


                        {
                                checkFileType(resource3) === 'Video' ? <video className=' my-auto mx-auto ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource3} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>
                     

                        <div className='item2  ratio ratio-1x1    rounded' >

                        {
                                checkFileType(resource4) === 'Video' ? <video className=' my-auto mx-auto ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                                    : <img className='  ' src={resource4} autoPlay muted style={{ objectFit: "cover" }} />
                            }
                        </div>
                    </div>
                        </Popup>
                    <div className="row">
                        <div className="col-10 col-md-10">
                            <motion.p className='mt-5  mx-auto image-text d-none d-md-block'
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5, type: "spring", stiffness: 260, damping: 20, }}
                            >
                                Il team di premium ID si è occupato dello studio creativo e della realizzazione dei contenuti. La protagonista del progetto è stata la talent Yousra Chaouki.
                            </motion.p>
                            <p className='mt-5  mx-auto image-text d-block d-md-none '
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1, delay: 0.5, type: "spring", stiffness: 260, damping: 20, }}
                            >
                                Il team di premium ID si è occupato dello studio creativo e della realizzazione dei contenuti. La protagonista del progetto è stata la talent Yousra Chaouki.
                            </p>
                        </div>
                    </div>
                </div>


            </div>
        </motion.div>

    );
};

export default StyleInfoSection;

import React, { useEffect, useRef } from "react";
import downArrow from "../assets/img/icon/downarrow.svg";
import { useTheme } from "../Context/useTheme";
import { useInView, useScroll, useTransform } from "framer-motion";
import HeadingAnimation from "./HeadingAnimation";

const WhatWeDoInfo = () => {
  const targetRef = useRef();
  const elementRef = useRef(null);
  const isInView = useInView(elementRef);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  useTransform(scrollYProgress, (pos) => {
    console.log(pos, "gg");
    return pos > 0.79 ? toggleTheme("light") : toggleTheme("light");
  });

  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);
  return (
    <>
      <div
        className="container-fluid bg-dark section-padding  custom-padding what-we-do-info  inline-twentynine"
        ref={targetRef}
      >
        <div className="row" ref={elementRef}>
          <div className="col-12 col-md-12 col-lg-12">
            <HeadingAnimation
              elementType={"h1"}
              classes="text-white text-uppercase big-heading  what-we-do-heading font-telegraf inline-thirty"
            >
              <span className="mobile-font-56px">We Are the</span>
              <span className=" text-primary   font-antonio  fw-bolder mobile-what-we-do-premium">
                {" "}
                PREMIUM revolution{" "}
              </span>
              <span
                className={`font-telegraf  what-we-do-heading mobile-what-we-do-ourworld mobile-font-56px ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                of Our{" "}
              </span>
              <span
                className={`inline-thirtyone ${
                  isInView ? "heading-animation" : ""
                }`}
              >
                world.
              </span>
            </HeadingAnimation>
          </div>
        </div>
        <div className="row mt-2 mt-md-5 ">
          <div className="col-md-6  col-sm-6  col-xl-3 col-lg-4 col-7 ">
            <p className="justified-text  inline-thirtytwo">
              Premium id is a creative company focused on influencer marketing.
              We manage{" "}
              <span className="text-primary fw-bold">original talents</span> who
              are able to create elite content for brands, especially in the
              fashion and beauty sectors.
            </p>
          </div>

          <div className="col-md-6  col-sm-6  col-lg-4  col-xl-3 mx-0 mx-xl-5  col-7">
            <p className="justified-text mt-2 inline-thirtythree">
              Premium Id means premium identities, it reflects{" "}
              <span className="fw-bold">the uniqueness of every</span> talent we
              represent and the target we want to reach for our clients and
              partners.
            </p>
          </div>
          <div className="col-md-12 offset-lg-10 offset-xl-10  col-lg-2  d-flex algin-items-center justiy-content-end arrow">
            <img
              src={downArrow}
              alt=""
              className="img-fluid   mx-auto my-auto"
            />{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoInfo;

import { motion, useAnimation, useTransform, useScroll } from "framer-motion";

import './style.css'
import { useRef } from "react";
import checkFileType from "../../../utils/CheckFileType";
import Popup from "../../popupanimaitons";
const ResumeSingleVideoSection = ({ secondVideo, resource1, resource2, desc, righttext,isLast ,heading}) => {

    const controls = useAnimation();
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end end"],
    });
    useTransform(scrollYProgress, (pos) => {
   
        if (pos >= 1 && !isLast) {
            controls.start("end");
        }

        else if (pos < 0.1 && pos > 0) {
            controls.start("initial");
        }
        else if (pos < 0.9 && pos > 0.1) {
            controls.start("start");
        }
    });

    const Varients = {
        initial: {
            opacity: 0,
        },
        start: {
            opacity: 1,
            y: 0,
            x: 0,

        },
        end: {
            opacity: 0,
            y: 600,
            x: 600,
            transition: { type: "spring", stiffness: 260, damping: 20, }
        }
    }
    return (
        <motion.div
            ref={targetRef}
            className="container-fluid bg-dark custom-padding section-padding min-vh-100 d-flex">
            <div className="row align-items-center flex-grow-1">
                <div className="col-12 col-md-4 align-self-start pb-5">
                    <div className="row  ">
                        <div className="col-12 col-md-12">
                            <h1 className='resume-heading text-uppercase'>{heading ? heading :"Resume"}</h1>
                        </div>
                        <div className="col-12 col-md-12">
                            <motion.p className='resume-description  d-lg-block d-none  mt-3'
                                initial="hidden"
                                animate={controls}
                                variants={Varients}
                            >
                                {desc}
                            </motion.p>
                            <p className='resume-description  d-lg-none d-block  mt-3'
                            >
                                {desc}
                            </p>
                        </div>
                    </div>
                    {secondVideo &&
                        <div className="col-12 col-md-12">
                            <Popup>


                            <div className="resume-video-2  ratio ratio-1x1 mx-auto">
                                {
                                    checkFileType(resource2) === 'Video' ? <video className=' my-auto mx-auto ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                                        : <img className='  ' src={resource2} autoPlay muted style={{ objectFit: "cover" }} />
                                }


                            </div>
                            </Popup>
                        </div>

                    }
                </div>
                <div className="col-12 col-md-1" />
                <div className="col-12 col-md-1 align-self-stretch py-5">
                    <motion.div className="resume-line"
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            delay: 1,
                            duration: 0.5,
                        }}
                    />
                </div>
                <div className="col-12 col-md-5 py-5  position-relative">

                    <Popup>

                    <div className=' resume-item-video  ratio ratio-1x1    rounded' >

                        {
                            checkFileType(resource1) === 'Video' ? <video className=' my-auto mx-auto ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} /> :
                                <img className='  ' src={resource1} autoPlay muted style={{ objectFit: "cover" }} />
                        }
                    </div>
                        </Popup>
                    <div className="beside-vertical-text">
                        {righttext}
                    </div>
                </div>

            </div>

        </motion.div>
    )
}

export default ResumeSingleVideoSection

// import LoaderGif from '../assets/img/loader/Loader.gif'; // Replace with the actual path to your GIF
import LoaderVideo from '../assets/img/loader/loader.mp4'; // Replace with the actual path to your GIF

const SplashScreen = ({setLoader}) => (
  <div
  className=' vh-100 d-flex align-items-center justify-content-center'
style={{background:"#000000"}}
  >
    {/* <img src={LoaderGif} alt="Loading..." className='img-fluid w-100' /> */}
    <video src={LoaderVideo} className='img-fluid w-75' autoPlay={true}  playsInline muted  onEnded={()=>setLoader(false)}></video>
  </div>
);

export default SplashScreen